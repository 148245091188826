import React from 'react'

import {
  Edit, TabbedForm, Datagrid,
  DisabledInput, TextInput, ReferenceArrayInput, AutocompleteArrayInput, BooleanInput, SelectInput,
  ReferenceManyField, ReferenceField, ChipField, TextField, DateField, required, translate
} from 'react-admin'

import MembersTitle from './MembersTitle'

import { TranslatedFormTab } from '../../pst/pst'

import VariablesEditor from './MembersVariablesEditor'

const _MemberEdit = props => (
    <Edit {...props} title={<MembersTitle />}>
        <TabbedForm>
            <TranslatedFormTab label="pst.members.formTabs.info">
                <DisabledInput source="id" />
                <TextInput source="name" validate={required()} />
                  <TextInput source="email" type="email" validate={required()} fullWidth helperText={props.translate('pst.members.emailHelper')} />
                <SelectInput
                  source="role"
                  validate={required()}
                  choices={[
                    {
                      id: 'admin',
                      name: 'pst.members.roles.admin'
                    },
                    {
                      id: 'member',
                      name: 'pst.members.roles.member'
                    }
                  ]}
                />
                <ReferenceArrayInput source="groups" reference="groups" filter={{archived: false}}>
                  <AutocompleteArrayInput optionText="label" />
                </ReferenceArrayInput>
                <BooleanInput source="active"/>
            </TranslatedFormTab>
            <TranslatedFormTab label="pst.members.formTabs.responses">
                <ReferenceManyField reference="responses" target="member" label="pst.members.responses">
                      <Datagrid>
                        <ReferenceField reference="responses" source="id">
                          <ChipField source="id"/>
                        </ReferenceField>
                        <DateField source="createdAt" showTime />
                        <DateField source="updatedAt" showTime />
                        <ReferenceField reference="forms" source="form">
                          <TextField source={`name.${props.locale}`}/>
                        </ReferenceField>
                      </Datagrid>
                </ReferenceManyField>
            </TranslatedFormTab>
            <TranslatedFormTab label="pst.members.formTabs.surveyVariables">
                <VariablesEditor source="variables"/>
            </TranslatedFormTab>
        </TabbedForm>
    </Edit>
)

export const MemberEdit = translate(_MemberEdit)
