import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { translate } from 'react-admin'

const style = theme => ({
    root: {
        flex: 1,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
});

const FormsStatus = props => {

  const { forms = [], translate, locale, classes } = props


  const buildString = record => {
    const started = translate('pst.dashboard.forms.started', { smart_count: record.startedCount })
    const draft = translate('pst.dashboard.forms.draft', { smart_count: record.draftCount })
    const completed = translate('pst.dashboard.forms.completed', { smart_count: record.completedCount })
    const total = translate('pst.dashboard.forms.total', { smart_count: record.members.length })
    return translate('pst.dashboard.forms.sentence', {started, draft, completed, total})
  }

  return (
    <Card className={classes.root}>
      <CardHeader title={translate('pst.dashboard.forms.publishedForms')} />
      <List dense={true}>
        {forms.map(record => (
          <ListItem
            key={record.id}
            button
            component={Link}
            to={`/forms/${record.id}/2`}
          >   
            <ListItemText
                primary={record.name[locale]}
                secondary={buildString(record)}
            />
              {
                record.members.length > 0 &&
                  <ListItemSecondaryAction>
                    <span className={classes.cost}>{Math.round(record.draftCount/record.members.length*1000)/10}% {translate('pst.dashboard.forms.ongoing')}</span>
                    <span className={classes.cost}>{Math.round(record.completedCount/record.members.length*1000)/10}% {translate('pst.dashboard.forms.finished')}</span>
                  </ListItemSecondaryAction>
              }
          </ListItem>
        ))}
      </List>
    </Card>
  )
}

FormsStatus.propTypes = {
  forms: PropTypes.array.isRequired
}

export default translate( withStyles(style)(FormsStatus) )