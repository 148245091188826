import React, {Fragment} from 'react'

import {
  Datagrid,
  TextField, EmailField, ReferenceArrayField, ChipField, SelectField,
  EditButton,
  SingleFieldList
} from 'react-admin'

import { Divider, Tabs } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

import { TranslatedListTab, ApplyFilter } from '../../pst/pst'

const StyledEmailField = withTheme()(
  props => (
    <EmailField style={{color: props.theme.palette.primary.main}} {...props} />
  )
)

const DatagridContent = props => (
  <Datagrid {...props} >
      <TextField source="id" />
      <TextField source="name" />
      <StyledEmailField source="email" />
      <SelectField source="role" choices={[
        {
          id: 'admin',
          name: 'pst.members.roles.admin'
        },
        {
          id: 'member',
          name: 'pst.members.roles.member'
        }
      ]} />
      <ReferenceArrayField reference="groups" source="groups" filter={{ archived: false }}>
          <SingleFieldList>
              <ApplyFilter filter={{ archived: false }}>
                <ChipField source="label"/>
              </ApplyFilter>
          </SingleFieldList>
      </ReferenceArrayField>
      <EditButton/>
  </Datagrid>
)

class TabbedDatagrid extends React.Component {
  
    tabs = [
        { id: 'active', name: 'pst.members.listTabs.active' },
        { id: 'inactive', name: 'pst.members.listTabs.inactive' },
    ]

    state = { active: [], inactive: [] }

    static getDerivedStateFromProps(props, state) {
        let filterKey = props.filterValues.active ? 'active' : 'inactive'
        if (props.ids !== state[filterKey]) {
            return { ...state, [filterKey]: props.ids }
        }
        return null
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props
        setFilters({ ...filterValues, active: value === 'active' })
    }

    render() {
        const { filterValues, translate, ...props } = this.props
        return (
            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.active ? 'active' : 'inactive'}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <TranslatedListTab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider />
                <div>
                    {filterValues.active === true && (
                       <DatagridContent
                            {...props}
                            ids={this.state['active']}
                        />
                    )}
                    {filterValues.active === false && (
                        <DatagridContent
                            {...props}
                            ids={this.state['inactive']}
                        />
                    )}
                </div>
            </Fragment>
        )
    }
}

export default TabbedDatagrid
