import React from 'react'
import PropTypes from 'prop-types'

import { translate } from 'react-admin'
import { Field } from 'redux-form'

import { JSONEditor, StyledCard } from './JSONEditor'

const JSONEditorInput = (props) => {

  const {
    // properties use and to pass
    history,
    allowedModes,
    readOnly,
    schema,
    // properties to remove
    translate,
    input,
    defaultValue,
    error,
    meta,
    className,
    locale,
    id,
    basePath,
    record,
    resource,
    label,
    // pass the rest
    ...rest
  } = props

  const defaultInitialValue = (typeof defaultValue !== 'undefined') ? defaultValue : '{}'
  let initialValue = (typeof input !== 'undefined' && input.value) ? input.value : defaultInitialValue
  const initialState = JSON.parse(initialValue)

  const [val, setVal] = React.useState(initialState)
  const [controlledVal, setControlledVal] = React.useState(initialState)

  const handleJSONChange = json => {
    // keep track of the value in internal state
    setVal(json)
    // call the input onChange handler with the new data
    input.onChange( JSON.stringify(json) )
  }

  const tryJson = (value, action) => {
    try {
      if (action === 'parse') {
        return JSON.parse(value)
      } else if (action === 'stringify') {
        return JSON.stringify(value)
      }  
    } catch (e) {
      return e
    }
  }

  // when the input value is changed externally
  React.useEffect(() => {
    const newValue = input.value
    const oldValue = tryJson(val, 'stringify')
    const parsedNewValue = tryJson(newValue, 'parse')

    if( !(oldValue instanceof Error) &&  !(parsedNewValue instanceof Error)) {
      // check if the new value is different from the value we kept track of
      if( oldValue !== newValue ) {
        // if it's different, set the controlled value
        setControlledVal( parsedNewValue )
      }
    }
  }, [input.value])

  // if our controlled val is changed, sync our keep track val with it
  React.useEffect(() => {
    setVal( controlledVal )
  }, [controlledVal])

  React.useEffect(() => {
    input.onChange( JSON.stringify(val) )
  }, [])

  return (
    <StyledCard square={true}>
      <JSONEditor
        { ...rest }
        value={controlledVal}
        onChange={handleJSONChange}
        //onError={handleJSONError}
        history={history || true}
        allowedModes={allowedModes || ['tree', 'code', 'view']}
        readOnly={readOnly}
        schema={schema}
      />
    </StyledCard>
  )
}

class FieldedJSONEditor extends React.Component {
  constructor(props) {
    super(props)
    this.editor = null
    this.validate = this.validate.bind(this)
    this.getRef = this.getRef.bind(this)
  }

  getRef(ref) {
    if(ref) {
      this.editor = ref
    }
  }

  validate(value) {
    if(this.editor) {
      try {
        this.editor.jsonEditor.get() // try to get data to check for syntax
        const valid = this.editor.jsonEditor.validateSchema(value)
        return valid === true ? undefined : 'Invalid JSON'
      } catch (e) {
        return 'Invalid JSON'
      }
    }
    return 'Invalid JSON'
  }

  componentWillUnmount() {
    if(this.editor !== null) {
      this.editor.jsonEditor.destroy()
    }
  }

  render() {
    const { source, translate, validate, ...rest } = this.props

    // if the provided validate prop is a function, insert it in an array, otherwise pass it directly
    const oldValidators = typeof validate === 'function' ? [validate] : validate

    // if the validators is an array (as set before or by dev), then prepend our new validator, otherwise ignore old validators
    const validators = Array.isArray(oldValidators) ? [this.validate, ...oldValidators] : [this.validate]

    return (
      <Field
        name={source}
        component={JSONEditorInput}
        translate={translate}
        validate={validators}
        getRef={this.getRef}
        {...rest}
    />
    )
  }
}

FieldedJSONEditor.propTypes = {
  validate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func
  ]),
}

const translated = translate(FieldedJSONEditor)

export { translated as JSONEditorInput }
