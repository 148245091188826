import { GET_ONE } from 'react-admin'

const addExportFeature = requestHandler => (type, resource, params) => {
    if (type === 'EXPORT' && resource === 'forms') {
      return requestHandler(GET_ONE, 'exports', params)
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params)
};

export default addExportFeature
