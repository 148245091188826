import React, { Fragment, useState, useEffect } from 'react'

import { Redirect } from 'react-router'

import {
  translate,
  Title,
  Responsive,
  withDataProvider,
  GET_LIST
} from 'react-admin'

import FormsStatus from './FormsStatus'
import Welcome from './Welcome'

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' }, // shares space horizontally with siblings
  leftCol: { flex: 1, marginRight: '1em' }, // left half of the screen
  rightCol: { flex: 1, marginLeft: '1em' }, // right half of the screen
  singleCol: { marginTop: '2em', marginBottom: '2em' }, // takes full column (either left or right)
}

const commonArrayValues = (array1, array2) => {
  
  const intersection = array1.filter( elt => array2.includes(elt) )
  return intersection.length > 0
  
}

const _DashboardContent = props => {

  const { translate, locale } = props
  
  const [formsList, setFormsList] = useState([])

  const fetchFormsCompletion = async () => {

    const { dataProvider } = props

    // fetch published forms
    const { data: forms } = await dataProvider(GET_LIST, 'forms', {
      filter: { status: 'published' },
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 100 },
    })

    // build our index
    const formsStatus = {}
    forms.forEach(form => {
      formsStatus[form.id] = {
        ...form,
        completedCount: 0,
        draftCount: 0,
        startedCount: 0,
      }
    })

    // fetch responses to these forms
    const { data: responses } = await dataProvider(GET_LIST, 'responses', {
      filter: { form: forms.map(form => form.id), status: ['draft', 'completed'] },
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 10000 },
    })

    // count number of draft, completed and total responses per form
    responses.forEach(response => {
      if (response.status === "draft") {
        formsStatus[response.form].draftCount++
      } else if (response.status === "completed") {
        formsStatus[response.form].completedCount++
      }
      formsStatus[response.form].startedCount++
    })


    // fetch members
    const { data: members } = await dataProvider(GET_LIST, 'members', {
      filter: {},
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 10000 },
    })

    const formsArray = Object.keys(formsStatus).map(form => {
      return formsStatus[form]
    })

    const formsArrayWithMembers = formsArray.map(form => {
      form.members = members.filter(member => {
        return commonArrayValues(member.groups, form.canReply)
      })
      return form
    })

    setFormsList(formsArrayWithMembers)

  }

  const fetchData = () => {
    fetchFormsCompletion()
  }
  
  useEffect(() => {

    fetchData()

  }, [])

  return (
    <Fragment>
      <Title title={translate('pst.dashboard.title')} />
      <Responsive
        xsmall={
          <div>
            <div style={styles.flexColumn}>
              <div style={styles.singleCol}>
                <Welcome />
              </div>
              <div style={styles.singleCol}>
                <FormsStatus forms={formsList}/>
              </div>
            </div>
          </div>
        }
        small={
          <div style={styles.flexColumn}>
            <div style={styles.singleCol}>
              <Welcome />          
            </div>
            <div style={styles.singleCol}>
              <FormsStatus forms={formsList}/>
            </div>
          </div>
        }
        medium={
          <div style={styles.flex}>
            <div style={styles.leftCol}>
              <div style={styles.singleCol}>
                <Welcome />
              </div>
            </div>
            <div style={styles.rightCol}>
              <div style={styles.singleCol}>
                <FormsStatus forms={formsList}/>
              </div>
            </div>
          </div>
        }
      />
    </Fragment>
  )

}

const DashboardContent = withDataProvider(_DashboardContent)


const Dashboard = props => {

  const { permissions, translate, ...rest } = props

  if(permissions === "admin") {

    return (<DashboardContent {...props} />)

  } else if(permissions === "member") {

    return (
      <Redirect to="/forms" />
    )

  }

  return null

}

export default translate(Dashboard)