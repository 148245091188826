import React from 'react'
import _ from 'lodash'

import {
  Datagrid,
  ChipField,
  TextField,
  withDataProvider,
  translate,
  GET_LIST
} from 'react-admin'

import {
  IconSelectField,
  StyledDraftIcon,
  StyledCompletedIcon,
  StyledIncompleteIcon,
  FakeReference
} from '../../pst/pst'

const HideEmpty = translate(
  (props) => {
    const { children, emptyText, translate, ...rest } = props
    if (_.get(props.record, props.source)) {
      return React.cloneElement(children, rest)
    } else {
      return emptyText ? <span>{translate(emptyText)}</span> : null
    }
  }
)

const ResponsesStatus = (props) => {
  
  const {
    dataProvider,
    dispatch,
    className,
    record,
    basePath,
    resource,
    translate,
    ...rest } = props

  const [sort, setSort] = React.useState({
    field: 'id',
    order: 'ASC'
  })
  const [data, setData] = React.useState({})
  const [ids, setIds] = React.useState([])
  const [loadedOnce, setLoadedOnce] = React.useState(false)
  const [total, setTotal] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)

  const fetchData = async () => {

    setIsLoading(true)

    const members = await dataProvider(GET_LIST, 'members', {
      filter: {
        groups: record.canReply
      },
      sort: {
        field: 'id',
        order: 'ASC'
      },
      pagination: { page: 1, perPage: 10000 },
    })

    const responses = await dataProvider(GET_LIST, 'responses', {
      filter: {
        form: record.id
      },
      sort: {
        field: 'updatedAt',
        order: 'DESC'
      },
      pagination: { page: 1, perPage: 10000 },
    })
    
    const result = {}

    members.data.forEach((member) => {
      const memberResponse = responses.data.find((response) => response.member === member.id)
      result[member.id] = {
        ...member,
        response: memberResponse
      }
    })

    setIsLoading(false)
    setTotal(members.total)
    setLoadedOnce(true)
    setData(result)
  }

  const handleSort = (field) => {
    var order = sort.field === field && sort.order === 'ASC' ? 'DESC' : 'ASC'

    setSort({
      field,
      order
    })
  }

  const sortData = () => {
    const sortArray = sort.field.split('.')
    const sortPrefix = sortArray.length > 1 ? sortArray[0] : null
    const sortField = sortArray.length > 1 ? sortArray[1] : sort.field

    const previousSort = ids.length ? ids : Object.keys(data)

    const dataAsArray = previousSort.map((id) => data[id])

    dataAsArray.sort((a, b) => {
      let sortElemA
      let sortElemB
      if (sortPrefix === 'response') {
        sortElemA = a.response ? a.response[sortField] : ''
        sortElemB = b.response ? b.response[sortField] : ''
      } else {
        sortElemA = a[sortField]
        sortElemB = b[sortField]
      }
      let result
      if (typeof sortElemA === 'string' || typeof sortElemB === 'string') {
        result = sortElemA.localeCompare(sortElemB, 'en', { sensitivity: 'base' })
      } else {
        result = sortElemA - sortElemB
      }
      return sort.order === 'ASC' ? result : -result
    })

    const sortedIds = dataAsArray.map((member) => member.id)

    setIds(sortedIds)
  }

  React.useEffect(() => {
    sortData()
  }, [sort, data])

  React.useEffect(() => {
    fetchData()
  }, [])

  const dataGridProps = {
    basePath: '/members',
    resource: 'members',
    className: className,
    setSort: handleSort,
    currentSort: sort,
    data: data,
    ids: ids,
    loadedOnce: loadedOnce,
    total: total
  }

  return (
      <Datagrid { ...dataGridProps } style={{width: '100%'}}>
        <FakeReference
          source="member.name"
          reference="member"
          isLoading={isLoading}
          linkType="edit"
        >
          <TextField source="name"/>
        </FakeReference>
        <HideEmpty source="response.id" emptyText="pst.forms.memberDidNotReply" >
          <FakeReference
            source="response.id" // used for sorting
            reference="response"
            isLoading={isLoading}
            linkType="edit"
          >
            <ChipField source="id" />
          </FakeReference>
        </HideEmpty>
        <IconSelectField
          source="response.status"
          choices={[
            {
              id: 'draft',
              name: 'pst.responses.status.draft',
              icon: <StyledDraftIcon />
            },
            {
              id: 'completed',
              name: 'pst.responses.status.completed',
              icon: <StyledCompletedIcon />
            },
            {
              id: undefined,
              name: 'pst.forms.memberDidNotReply',
              icon: <StyledIncompleteIcon />
            }
          ]}
        />
      </Datagrid>
  )
}

export default translate(withDataProvider(ResponsesStatus))
