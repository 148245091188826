import React from 'react'
import PropTypes from 'prop-types'

import Survey from './survey'

const SurveyField = props => {
  
  const {record, ...rest} = props
  
  const input = {
    value: record.data,
  }
  
  return (
    <Survey {...props}
      input={input}
      formid={record.form}
      memberid={record.member}
      status="completed"
      />
  )
  
}

SurveyField.propTypes = {
  record: PropTypes.shape({
    data: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    member: PropTypes.string.isRequired,
  })
}

export default SurveyField