import React from 'react'

import {
  translate
} from 'react-admin'

import {Card, CardContent, Typography} from '@material-ui/core'

import { getUser } from '../pst/pst'

const Welcome = ({translate}) => (
  <Card>
    <CardContent>
      <Typography variant="headline" paragraph>{translate('pst.dashboard.welcome', {name: getUser().name})}</Typography>
    </CardContent>
  </Card>
)

export default translate(Welcome)
