import React from 'react'

import {
  List,
} from 'react-admin'

import TabbedDatagrid from './MemberResponseTabbedDatagrid'

export const MemberResponseList = props => (
    <List {...props}
      filterDefaultValues={{ status: 'draft' }}
      sort={{ field: 'id', order: 'ASC' }}
      exporter={false}
      bulkActionButtons={false}
      actions={null}
      >
        <TabbedDatagrid />
    </List>
)
