import React from 'react'
import { translate } from 'react-admin'

const MembersTitle = ({ record, translate }) => {

  if (Object.keys(record).length) {
    return <span>{translate('pst.members.title.edit', { name: record.name } )}</span>
  } else {
    return <span>{translate('pst.members.title.create')}</span>
  }

}

export default translate(MembersTitle)
