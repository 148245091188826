import React from "react"

import { showNotification, translate, LinearProgress } from 'react-admin'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
 
import { BooleanSwitch, JSONEditor, StyledCard } from '../pst/pst'

import 'jsonminify'

import Submit from './Submit'
import Upload from './Upload'
import ClearButton from './ClearButton'

const styles = {
  results: {
    table: {
      paddingLeft: 0,
      width: '100%',
    },
    tableRow: {
      display: 'flex',
      marginBottom: 5
    },
    tableCell: {
      backgroundColor: '#fdd',
      padding: '5px 10px',
      flexBasis: '20%'
    },
    tableCellMsg: {
      flex: 1
    }
  }
}

const schema = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    properties: {
      id: {
        type: 'string',
        minLength: 24,
        maxLength: 24
      },
      variables: {
        type: 'object',
      }
    },
    required: ['id', 'variables']
  }
}

const options = {
  templates: [
    {
      text: 'Member',
      title: 'Insert a member node',
      value: {
          'id': '',
          'variables': {
            'locale': ''
          }
      }
    }
  ]
}

function Importer (props) {
  
  const [isDisabled, setDisabled] = React.useState(true)
  const [val, setVal] = React.useState([])
  const [controlledVal, setControlledVal] = React.useState([])
  const [file, setFile] = React.useState(null)
  const [readOnly, setReadOnly] = React.useState(false)
  const [absoluteImport, setAbsoluteImport] = React.useState(false)
  const [results, setResults] = React.useState([])
  const [count, setCount] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const { showNotification, translate, locale } = props
  
  const handleJSONChange = json => {
    
    setVal(json)

  }

  const handleJSONValid = valid => {

    setDisabled(!valid)

  }

  const handleResult = (success, id, done, message) => {

    // count the number of callbacks created to have the total number of members
    if (!success) {
      setCount(count => count+1)
    }

    return () => {

      const newResult = {
        success,
        id,
        done,
        message
      }

      if (done) {
        showNotification('pst.importer.notifications.done', 'info')
        setLoading(false)
      }

      setResults(oldResults => [...oldResults, newResult])

    }

  }
  
  const handleFileChange = event => {

    const newFile = event.target.files[0]
    const reader = new FileReader()

    // register event for when the file has finished loading
    reader.onload = (event) => {
      
        try {

            const fileContent = event.target.result
            let fileObject
         
            // Try to parse the JSON file
            try {
              fileObject = JSON.parse(JSON.minify(fileContent))
            } catch (e) {
              throw new Error('pst.importer.notifications.invalidJSON')
            }

            // Check if root element is an array
            if( !Array.isArray(fileObject) ) {
              throw new Error('pst.importer.notifications.invalidRoot')
            }

            // Parse each member
            const results = fileObject.map( member => ({
              id: member.id,
              variables: member.variables
            }) )

            // update our state
            setFile(newFile)
            setControlledVal(results)
            setReadOnly(true)

        } catch (e) {

            showNotification(e.message, 'warning')

        }

    }
    
    try {
    
      if ( !newFile.type.match('application.json') ) {
        throw new Error('pst.importer.notifications.invalidFileType')
      }
      
      // Read in the file
      reader.readAsText(newFile)

    } catch(e) {

      showNotification(e.message, 'warning')

    }

  }
  
  const handleClearFile = event => {
        
    setFile(null)
    setControlledVal([])
    setReadOnly(false)
    
  }

  const handleClearResults = () => {
    setCount(0)
    setResults([])
  }

  const handleToggleAbsolute = () => {
    setAbsoluteImport(!absoluteImport)
  }
  
  const handleSubmit = () => {
    setLoading(true)
  }

  React.useEffect(() => {
    setVal(controlledVal)
  }, [controlledVal])

  let instruction
  
  if (readOnly) {
    instruction = (<Typography variant="body2" color="error" style={{display:'inline-flex', marginLeft: '10px', alignItems: 'center'}}>{translate('pst.importer.readOnly')}</Typography>)
  } else {
    instruction = (<Typography variant="body2" color="textSecondary" style={{display:'inline-flex', marginLeft: '10px', alignItems: 'center'}}>{translate('pst.importer.enterJSONorUpload')}</Typography>)
  }
  
  const errors = results.filter(result => !result.success)
  const success = results.filter(result => result.success)

  return(
    <form noValidate autoComplete="off">
      <div style={{marginBottom: "20px"}}>
        <StyledCard square={true}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            { instruction }
            <BooleanSwitch
              {...props}
              label={translate('pst.importer.absoluteImport')}
              value={absoluteImport}
              onChange={handleToggleAbsolute}
            />
          </div>
          <JSONEditor
            value={controlledVal}
            onChange={handleJSONChange}
            isValid={handleJSONValid}
            history={true}
            allowedModes={['tree', 'code', 'view']}
            readOnly={readOnly}
            schema={schema}
            options={options}
          />
        </StyledCard>
      </div>
      <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <Upload onChange={handleFileChange} onClear={handleClearFile} file={file} value=""/>
        <Submit disabled={isDisabled} data={val} absoluteImport={absoluteImport} handleResult={handleResult} onSubmit={handleSubmit}/>
      </div>
      <div style={{marginTop: 50}}>
        <Typography variant="headline" gutterBottom>{translate('pst.importer.results')}
          {
            (!loading && results.length > 0) && (
              <ClearButton onClick={handleClearResults} label={translate('pst.importer.clearResults')} style={{marginLeft: 20}}/>
            )
          }
        </Typography>

        {
          loading && <LinearProgress />
        }

        <Typography variant="body1">
          {
            results.length > 0 ? (
              translate('pst.importer.updatedSuccess', { smart_count: success.length, total_count: count })
            ) : (
              translate('pst.importer.launchForResults')
            )
          }
        </Typography>

        {
          errors.length > 0 && (
            <div>        
              <Typography variant="body1">
                {
                  translate('pst.importer.updatedFailure', { smart_count: errors.length })
                }
              </Typography>
              <ul style={styles.results.table}>
                {
                  errors.map((result, index) => (
                      <li key={index} style={styles.results.tableRow}>
                        <Typography variant="body1" style={styles.results.tableCell}>{ result.id }</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            ...styles.results.tableCell,
                            ...styles.results.tableCellMsg
                          }}>
                            { translate(result.message) }
                          </Typography>
                      </li>
                  ))
                }
              </ul>
            </div>
          )
        }
      </div>
    </form>
  )
  
}

export default translate(
  connect(null, {
    showNotification,
  })(Importer)
)
