import React from 'react'

import {
  Edit, TabbedForm, CardActions,
  DisabledInput, TextInput, ReferenceArrayInput, AutocompleteArrayInput, SelectInput,
  DateField,
  CloneButton, required
} from 'react-admin'

import FormsTitle from '../FormsTitle'

import {
  TranslatedFormTab
} from '../../pst/pst'

import ResponsesStatus from './ResponsesStatus'
import SurveyEditorInput from './survey-editor-input'
import ExportResponsesButton from './ExportResponsesButton'

const FormEditActions = ({ basePath, data, resource }) => (
    <CardActions>
        <CloneButton basePath={basePath} record={data}/>
        <ExportResponsesButton basePath={basePath} record={data}/>
    </CardActions>
)

export const FormEdit = props => (
    <Edit {...props} title={<FormsTitle action="edit"/>} actions={<FormEditActions/>}>
        <TabbedForm>
            <TranslatedFormTab label="pst.forms.formTabs.info">
                <DisabledInput source="id" />
                <TextInput source="name.en" validate={required()}/>
                <TextInput source="name.de" />
                <TextInput source="name.fr" />
                <TextInput source="name.it" />
                <ReferenceArrayInput source="canReply" reference="groups" filter={{archived: false}}>
                    <AutocompleteArrayInput optionText="label" />
                </ReferenceArrayInput>
                <SelectInput
                  source="status"
                  validate={required()}
                  choices={[
                    {
                      id: 'draft',
                      name: 'pst.forms.status.draft'
                    },
                    {
                      id: 'published',
                      name: 'pst.forms.status.published'
                    },
                    {
                      id: 'completed',
                      name: 'pst.forms.status.completed'
                    }
                  ]}
                />
                <DateField source="createdAt" showTime />
                <DateField source="updatedAt" showTime />
            </TranslatedFormTab>
            <TranslatedFormTab label="pst.forms.formTabs.editor">
                <SurveyEditorInput source="data" />
            </TranslatedFormTab>
            <TranslatedFormTab label= "pst.forms.formTabs.responses">
              <ResponsesStatus />
            </TranslatedFormTab>
        </TabbedForm>
    </Edit>
)
