import React, { Children } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles, createStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import {
  LinearProgress,
  Link
} from 'react-admin'

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation()

const styles = theme =>
  createStyles({
    link: {
        color: theme.palette.primary.main,
    },
})

const sanitizeRestProps = (props) => _.omit(props, [
  'addLabel',
  'allowEmpty',
  'basePath',
  'cellClassName',
  'className',
  'formClassName',
  'headerClassName',
  'label',
  'linkType',
  'locale',
  'record',
  'resource',
  'sortable',
  'sortBy',
  'source',
  'textAlign',
  'translateChoice',
])

const _ReferenceFieldView = ({
    allowEmpty,
    basePath,
    children,
    className,
    classes = {},
    isLoading,
    record,
    reference,
    referenceRecord,
    resource,
    resourceLinkPath,
    source,
    translateChoice = false,
    ...rest
}) => {
    if (isLoading) {
        return <LinearProgress />
    }

    if (resourceLinkPath) {
        return (
            <Link
                to={resourceLinkPath}
                className={className}
                onClick={stopPropagation}
            >
                {React.cloneElement(Children.only(children), {
                    className: classnames(
                        children.props.className,
                        classes.link // force color override for Typography components
                    ),
                    record: referenceRecord,
                    resource: reference,
                    allowEmpty,
                    basePath,
                    translateChoice,
                    ...sanitizeRestProps(rest),
                })}
            </Link>
        )
    }

    return React.cloneElement(Children.only(children), {
        record: referenceRecord,
        resource: reference,
        allowEmpty,
        basePath,
        translateChoice,
        ...sanitizeRestProps(rest),
    })
}

_ReferenceFieldView.propTypes = {
    allowEmpty: PropTypes.bool,
    basePath: PropTypes.string,
    children: PropTypes.element,
    className: PropTypes.string,
    classes: PropTypes.object,
    isLoading: PropTypes.bool,
    record: PropTypes.object,
    reference: PropTypes.string,
    referenceRecord: PropTypes.object,
    resource: PropTypes.string,
    resourceLinkPath: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    source: PropTypes.string,
    translateChoice: PropTypes.bool,
}

export const ReferenceFieldView = withStyles(styles)(_ReferenceFieldView)
