import React from 'react'
import PropTypes from 'prop-types'

export const ApplyFilter = props => {

  const {filter, record, children, ...rest} = props

  let show = true
  Object.keys(filter).forEach(filterKey => {
    if(typeof record !== 'undefined') {
      if(typeof record[filterKey] === "undefined" || record[filterKey] !== filter[filterKey]) {
        show = false
      }
    }
  })

  if(show) {
    return React.cloneElement(children, {record, ...rest})
  } else {
    return (null)
  }
}

ApplyFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  record: PropTypes.object,
  children: PropTypes.object.isRequired
}