import React from 'react'

import {
  Show, SimpleShowLayout,
} from 'react-admin'

import ResponsesTitle from '../ResponsesTitle'

import SurveyField from '../survey-field'

export const MemberResponseShow = props => {
      
  return (
    <Show {...props}
      actions={null}
      title={<ResponsesTitle/>} >
        <SimpleShowLayout>
          <SurveyField
            source="data"
            {...props}
          />
        </SimpleShowLayout>
    </Show>
  )
  
}
