import React, { Fragment } from 'react'

import {
  Datagrid,
  TextField,
  EditButton,
  SingleFieldList
} from 'react-admin'

import { Divider, Tabs } from '@material-ui/core'

import { TranslatedListTab } from '../../pst/pst'

const DatagridContent = props => (
  <Datagrid { ...props } >
    <TextField source="id" />
    <TextField source="label" />
    <EditButton />
  </Datagrid>
)

class TabbedDatagrid extends React.Component {

  tabs = [
    { id: 'current', name: 'pst.groups.listTabs.current' },
    { id: 'archived', name: 'pst.groups.listTabs.archived' },
  ]

  state = { current: [], archived: [] }

  static getDerivedStateFromProps (props, state) {
    let filterKey = props.filterValues.archived ? 'archived' : 'current'
    if (props.ids !== state[ filterKey ]) {
      return { ...state, [ filterKey ]: props.ids }
    }
    return null
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props
    setFilters({ ...filterValues, archived: value === 'archived' })
  }

  render () {
    const { filterValues, translate, ...props } = this.props
    return (
      <Fragment>
        <Tabs
          fullWidth
          centered
          value={ filterValues.archived ? 'archived' : 'current' }
          indicatorColor="primary"
          onChange={ this.handleChange }
        >
          { this.tabs.map(choice => (
            <TranslatedListTab
              key={ choice.id }
              label={ choice.name }
              value={ choice.id }
            />
          )) }
        </Tabs>
        <Divider />
        <div>
          { filterValues.archived === true && (
            <DatagridContent
              { ...props }
              ids={ this.state[ 'archived' ] }
            />
          ) }
          { filterValues.archived === false && (
            <DatagridContent
              { ...props }
              ids={ this.state[ 'current' ] }
            />
          ) }
        </div>
      </Fragment>
    )
  }
}

export default TabbedDatagrid
