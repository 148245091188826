import React from 'react'
import _ from 'lodash'

import { linkToRecord } from 'react-admin'

import { ReferenceFieldView } from './ReferenceFieldView'

export const FakeReference = (props) => {
  const { reference, record, isLoading, linkType, children } = props

  const originalResource = props.resource

  const resource = reference + 's'
  const basePath = '/' +resource

  const getLink = () => {
    const linkId = originalResource === resource ? record.id : _.get(record, reference+'.id')
    const resourceLinkPath = !linkType
      ? false
      : linkToRecord(basePath, linkId, linkType)
    return resourceLinkPath
  }

  return (
    <ReferenceFieldView
      allowEmpty={true}
      basePath={basePath}
      isLoading={isLoading}
      record={record}
      reference={reference}
      referenceRecord={originalResource === resource ? record : _.get(record, reference)}
      resource={reference}
      resourceLinkPath={getLink()}
      children={children}
    />
  )
}
