import React from 'react'

import {
  Create, TabbedForm, required,
  TextInput, ReferenceArrayInput, AutocompleteArrayInput, SelectInput
} from 'react-admin'

import FormsTitle from '../FormsTitle'

import { TranslatedFormTab } from '../../pst/pst'

import SurveyEditorInput from './survey-editor-input'

export const FormCreate = props => {
  return (
    <Create { ...props } title={ <FormsTitle action="create" /> }>
      <TabbedForm>
        <TranslatedFormTab label="pst.forms.formTabs.info">
          <TextInput source="name.en" validate={ required() } />
          <TextInput source="name.de" />
          <TextInput source="name.fr" />
          <TextInput source="name.it" />
          <ReferenceArrayInput source="canReply" reference="groups" filter={{archived: false}}>
              <AutocompleteArrayInput optionText="label" />
          </ReferenceArrayInput>
          <SelectInput
            source="status"
            validate={ required() }
            choices={ [
              {
                id: 'draft',
                name: 'pst.forms.status.draft'
              },
              {
                id: 'published',
                name: 'pst.forms.status.published'
              },
              {
                id: 'completed',
                name: 'pst.forms.status.completed'
              }
            ] }
          />
        </TranslatedFormTab>
        <TranslatedFormTab label="pst.forms.formTabs.editor">
          <SurveyEditorInput source="data" />
        </TranslatedFormTab>
      </TabbedForm>
    </Create>
  )
}
