import React from 'react'
import PropTypes from 'prop-types'

import {
  FormControlLabel,
  FormGroup,
  Switch
} from '@material-ui/core'

import { FieldTitle } from 'ra-core'

export const BooleanSwitch = props => {
  
  const {
      className,
      id,
      input,
      isRequired,
      label,
      options,
      fullWidth,
      onChange,
      value,
      ...rest
  } = props

  return (
      <FormGroup className={className}>
          <FormControlLabel
              htmlFor={id}
              control={
                  <Switch
                      id={id}
                      color="primary"
                      checked={!!value}
                      onChange={onChange}
                      {...options}
                  />
              }
              label={
                  <FieldTitle
                      label={label}
                      isRequired={isRequired}
                  />
              }
          />
      </FormGroup>
  )

}

BooleanSwitch.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    isRequired: PropTypes.bool,
}