import React from 'react'

import {
  Edit, TabbedForm, FormDataConsumer,
  DisabledInput, SelectInput,
  ReferenceField, TextField, DateField, required, translate
} from 'react-admin'

import ResponsesTitle from '../ResponsesTitle'

import { TranslatedFormTab } from '../../pst/pst'

import SurveyInput from '../survey-input'

const _AdminResponseEdit = props => (
    <Edit {...props} title={<ResponsesTitle/>}>
        <TabbedForm>
            <TranslatedFormTab label="pst.responses.formTabs.reply">
                <FormDataConsumer>
                      {({ formData, ...rest }) =>
                          <SurveyInput
                            source="data"
                            formid={formData.form}
                            memberid={formData.member}
                            status={formData.status}
                               {...rest}
                          />
                      }
                </FormDataConsumer>
            </TranslatedFormTab>
            <TranslatedFormTab label="pst.responses.formTabs.info">
                <DisabledInput source="id" />
                <ReferenceField source="form" reference="forms" validate={required()}>
                  <TextField source={`name.${props.locale}`} />
                </ReferenceField>
                <SelectInput
                  source="status"
                  validate={required()}
                  choices={[
                    {
                      id: 'draft',
                      name: 'pst.responses.status.draft'
                    },
                    {
                      id: 'completed',
                      name: 'pst.responses.status.completed'
                    }
                  ]}
                />
                <ReferenceField source="member" reference="members" validate={required()}>
                  <TextField source="name" />
                </ReferenceField>
                <DateField source="createdAt" showTime />
                <DateField source="updatedAt" showTime />
            </TranslatedFormTab>
        </TabbedForm>
    </Edit>
)

export const AdminResponseEdit = translate(_AdminResponseEdit)