import React, { useState, useEffect } from 'react'

import { Button, Link, translate, Mutation, Query, GET_ONE } from 'react-admin'

import { dataProvider } from '../../providers/providers'

//import AssignmentIcon from '@material-ui/icons/Assignment'
import EditIcon from '@material-ui/icons/Edit'
import CompletedIcon from '@material-ui/icons/CheckCircle'

import jwt from 'jsonwebtoken'

const options = {
  onSuccess: {
    notification: {
      body: 'pst.responses.survey.create.success',
      level: 'info'
    },
    redirectTo: (basePath, id, data) => {
      return `/responses/${id}`
    }
  },
  onFailure: {
    notification: {
      body: 'pst.responses.survey.create.error',
      level: 'warning'
    },
  }
}

const _CreateResponseButton = props => {
  
  const { record, translate } = props
  
  const [member, setMember] = useState(false)

  const token = sessionStorage.getItem('token')
  const decoded = jwt.decode(token)
  
  const loadMember = () => {
    dataProvider(GET_ONE, 'members', {id: decoded.id})
      .then( (member) => {
        setMember(member.data)
      })
  } 

  React.useEffect( () => {
    loadMember()
  }, [] )
  
  if( member ) {

    const payload = {
      data: {
        form: record.id,
        status: 'draft',
        member: decoded.id,
        data: JSON.stringify(member.variables)
      }
    }

    return (
      <Mutation
          type="CREATE"
          resource="responses"
          payload={payload}
          options={options}
      >
        {(create) => (
            <Button
              onClick={create}
              color='primary'
              label={translate('pst.forms.reply')}
            >
              <EditIcon />
            </Button>
          )}
        </Mutation>
      )

  } else {

    return (
      <div>Loading...</div>
    )

  }

}

const _ResponseButtons = props => {
  
  const { record, translate } = props
  
  const token = sessionStorage.getItem('token')
  const decoded = jwt.decode(token)
  
  const payload = {
    pagination: {
      page: 1,
      perPage: 1
    },
    sort: {
      field: 'id',
      order: 'ASC'
    },
    filter: { form: record.id, member: decoded.id }
  }
  
  return (
    <Query type="GET_LIST" resource="responses" payload={payload}>
      {({ data, total, loading, error }) => {
          if (loading) { return <p>Loading</p> }
          if (error) { return <p>ERROR</p> }
          if (total > 0) {
            if (data[0].status === 'draft') {
              return (
                <Button
                    component={Link}
                    to={{
                        pathname: `/responses/${data[0].id}`,
                    }}
                    color='primary'
                    label={translate('pst.forms.editReply')} >
                  <EditIcon />
                </Button>
              )
            } else {
              return (
                <Button
                  component={Link}
                  to={{
                    pathname: `/responses/${data[0].id}/show`,
                  }}
                  color='primary'
                  label={translate('pst.forms.replied')} >
                  <CompletedIcon />
                </Button>
              )
            }
          } else {
            return <_CreateResponseButton {...props} />
          }
        
      }}
    </Query>
  )
  
}

export const CreateResponseButton = translate(_CreateResponseButton)
export const ResponseButtons = translate(_ResponseButtons)
