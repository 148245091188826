import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { Stepper, Step, StepButton, StepLabel, Button, Typography } from '@material-ui/core'

import { translate } from 'react-admin'

const styles = theme => ({
  root: {
    marginTop: '15px'
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  stepLabelCompleted: {
    color: theme.palette.success.main +' !important'
  }
})

const toLocaleTimeString = (date, locale) => {
  try {
    return date.toLocaleTimeString(locale)
  } catch (e) {
    if (e.name === "RangeError") {
      return date.toLocaleTimeString()
    }
    throw e
  }
}

const HorizontalNonLinearStepper = props => {
  
  const { survey, classes, activeStep, onChange, onComplete, validated, translate, locale, status, saved, children } = props
  
  const [visited, setVisited] = useState({})

  const setActiveStep = onChange
  const steps = survey.visiblePages
  
  const totalSteps = () => steps.length
  
  // Helpers
  
  const validatedSteps = () => {
    const valid = Object.keys(validated).filter(step => (validated[step]))
    return valid.length
  }
  
  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsValidated = () => {
    return validatedSteps() === totalSteps()
  }
  
  // Handles
  
  const triggerStep = step => {
    let newVisited = {
      ...visited,
      [activeStep]: true
    }
    setVisited(newVisited)
    setActiveStep(step)
  }
  
  const handleStep = step => () => {
    triggerStep(step)
  }
  
  const handleNext = () => {
    let newActiveStep
    if (isLastStep() && !allStepsValidated()) {
      // It's the last step, but not all steps have been visited,
      // find the first step that has been validated
      const firstNotVisited = steps.findIndex((step, i) => !(i in visited))
      const firstNotValid = steps.findIndex((step, i) => !(i in validated))
      newActiveStep = Math.min(firstNotValid, firstNotVisited)
    } else {
      newActiveStep = activeStep + 1
    }
    triggerStep(newActiveStep)
  }

  const handleBack = () => {
    triggerStep(activeStep - 1)
  }

  // Render
  
  const lastSaved =   saved instanceof Date ? translate('pst.responses.survey.save.success', { time: toLocaleTimeString(saved, locale) }) : saved
  
  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((step, index) => {
          const current = index === activeStep
          const considerVisited = (visited[index] || activeStep > index)
          const error = !current && considerVisited && validated[index] === false
          const completed = !current && status !== 'completed' && considerVisited && !error
          return (
            <Step key={index}>
              <StepButton onClick={handleStep(index)} completed={completed}>
                <StepLabel
                  error={error}
                  StepIconProps={{ 
                    classes: { completed: classes.stepLabelCompleted } 
                  }}
                >
                  {step.title}
                </StepLabel>
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
      <div>
        {children}
        <div style={{display:'flex', justifyContent: 'space-between'}}>
          <span>
            { saved && (
              <Typography>
                {lastSaved}
              </Typography>
            )}
          </span>
          <span>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
            >
              {translate('pst.responses.survey.previousPage')}
            </Button>
            <Button
              disabled={activeStep === totalSteps() - 1}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}>
              {translate('pst.responses.survey.nextPage')}
            </Button>
            {
              status !== 'completed' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onComplete}>
                  {translate('pst.responses.survey.finish')}
                </Button>
              )
            }
          </span>
        </div>
      </div>
    </div>
  )  
}

HorizontalNonLinearStepper.propTypes = {
  survey: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  validated: PropTypes.object.isRequired,
  status: PropTypes.string,
  saved: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.bool
  ])
}

export default translate(withStyles(styles)(HorizontalNonLinearStepper))