import React from 'react'
import PropTypes from 'prop-types'

import {
    GET_ONE,
    UPDATE,
    CRUD_UPDATE_SUCCESS,
    FETCH_END,
    translate,
    showNotification,
    withDataProvider
} from 'react-admin'

import * as SJS from 'survey-react'
import 'survey-react/survey.min.css'

import { Typography } from '@material-ui/core'

import { SurveyTheme, getLocale } from '../pst/pst'

import SurveyNavigation from './SurveyNavigation'
import SurveyErrorsPopup from './SurveyErrorsPopup'

/*

1. Survey content is loaded in input prop if it exists                  - component mount
2. When form is selected, it loads it                                   - component mount or form change
3. Survey is created with loaded form                                   - form change
4. Survey is populated with member content and existing survey content  - survey change

*/

const styles = {
  status: {
    marginTop: 20
  }
}

const Survey = props => {
  
  const {record, formid, memberid, input, translate, onComplete, dataProvider, resource, dispatch, status} = props
  
  // State management
  
  const [survey, setSurvey] = React.useState(null)
  const [page, setPage] = React.useState(null)
  const [validated, setValidated] = React.useState({})
  const [saved, setSaved] = React.useState(false)
  const [openPopup, setOpenPopup] = React.useState(false)

  // Theming SurveyJS

  SJS.StylesManager.ThemeColors["petzi"] = SurveyTheme
  SJS.StylesManager.applyTheme("petzi")
  
  // Loading data
  
  // Load the form from the server to generate the survey
  const loadForm = () => {

    if (formid) {

      return dataProvider(GET_ONE, 'forms', {id: formid})
        .then( (form) => {
          return form.data
        })

    } else {

      return Promise.reject(new Error('No formid provided'))
      
    }

  }
  
  // Load the member from the server for the variables
  const loadMember = () => {

    if (memberid) {

      return dataProvider(GET_ONE, 'members', {id: memberid})
        .then( (member)=> {
          return member.data
        })

    } else {

      return Promise.reject(new Error('No memberid provided'))

    }

  }

  // Events

  const handleSurveyValidate = (result, show) => {

    show = typeof show === 'undefined' ? true : show

    const pages = result.visiblePages

    pages.forEach(page => {

      const hasErrors = page.hasErrors(false, false)
      
      setValidated(oldValidated => ({
        ...oldValidated,
        [page.visibleIndex]: !hasErrors
      }))

    })
    
  }

  // Trigger validation
  const handlePageValidate = (result, show) => {
    
    show = typeof show === 'undefined' ? true : show

    const hasErrors = typeof result.currentPage !== 'undefined' && result.currentPage.hasErrors(show, false)
    
    const newValidated = {
      ...validated,
      [result.currentPageNo]: !hasErrors
    }
    
    setValidated(newValidated)
    
    return hasErrors

  }

  // handle saving/passing the data further
  const handleSave = (result) => {

    if (status !== 'completed' && typeof input.onChange !== 'undefined') {

      const data = JSON.stringify(result.data)

      if ( record.data !== data && typeof record.id !== 'undefined' ) {

        dataProvider(UPDATE, 'responses', {
          id: record.id,
          data: {
            ...record,
            data
          }
        }).then((result) => {
          setSaved(new Date())
          dispatch({
            type: CRUD_UPDATE_SUCCESS,
            payload: result,
            meta: {
              resource,
              fetchResponse: UPDATE,
              fetchStatus: FETCH_END
            }
          })
        }).catch((e) => {
          dispatch(showNotification(e.message, 'warning'))
        })

      }

    }
    
  }

  // When a value is changed
  const valueChanged = (result, options) => {

    input.onChange(JSON.stringify(result.data))

    handlePageValidate(result, false)

  }
  
    
  const handleComplete = () => {

    handleSave(survey)

    if( survey.hasErrors(true, false) ){

      setOpenPopup(true)

    }
    
    survey.completeLastPage()

  }

  // when the user clicks "complete" button
  const beforeComplete = (survey, options) => {

    // trigger validation
    if( survey.hasErrors(true, false) ){

      options.allowComplete = false

      handleSurveyValidate(survey)

    } else {

      options.allowComplete = true

    }

  }

  const handlePopupClose = () => {
    setOpenPopup(false)
  }
  
  // When survey is completed
  const complete = result => {

    if (typeof onComplete !== 'undefined' && typeof input.onChange !== 'undefined') {
    
      input.onChange(JSON.stringify(result.data))
      onComplete(JSON.stringify(result.data))
    
    }

  }
  
  // change page action (triggered from SurveyNavigation)
  const changePage = page => {

    page = typeof page.target === 'undefined' ? page : page.target.value
    survey.currentPageNo = page
    setPage(survey.currentPageNo)
  
  }
  
  // when page is changing
  const pageChanging = (survey, options) => {

    handleSave(survey)

    handlePageValidate(survey)

  }
  
  // Initialise survey with default values or previously entered content, based on form
  const initSurvey = (form, member) => {

    if (form && member) {


      // Set default response value
      let response = {}

      // Check if there is already a response to populate the survey
      if (input.value) {
        response = JSON.parse(input.value)
      }
      
      // Create survey
      const model = new SJS.Model(JSON.parse(form.data))

      // Populate the response with the default form data, what have already been replied, and user variables
      model.data = {                        // Copy default response/data into survey
        ...model.data,
        ...member.variables,
        ...response
      }

      // if survey is completed, set it in read only mode
      if (status === 'completed') {
        model.mode = 'display'
      }

      // set the locale
      model.locale = getLocale()

      setPage(model.currentPageNo)

      // save the survey in state
      setSurvey(model)

      handleSurveyValidate(model, false)

    }

  }

  // Entrypoint
  const buildSurvey = async () => {
    
    try {

      const form = await loadForm()
      const member = await loadMember()

      initSurvey(form, member)

    } catch (e) {

      console.warn(`Couln't load form or member`, e.message)

    }

  }

  // Component did mount
  React.useEffect( () => {

    buildSurvey()

  }, [] )

  // When survey reference changes, trigger save
  React.useEffect( () => {

    if (survey) {
      handleSave(survey)
    }

  }, [survey] )
  
  // Check if all necessary information has been loaded

  if (!formid) {
    return (<Typography variant="body1" style={styles.status}>{translate('pst.responses.survey.selectForm')}</Typography>)
  }
  
  if (!memberid) {
    return (<Typography variant="body1" style={styles.status}>{translate('pst.responses.survey.selectMember')}</Typography>)
  }
  
  if (!survey) {
    return (<Typography variant="body1" style={styles.status}>{translate('pst.responses.survey.loading')}</Typography>)
  }
    
  return (
    <div>
      <SurveyErrorsPopup open={openPopup} validated={validated} onClose={handlePopupClose} survey={survey} />
      <SurveyNavigation survey={survey} activeStep={page} onChange={changePage} validated={validated} status={status} saved={saved} onComplete={handleComplete} >
        <SJS.Survey
          model={survey}
          onValueChanged={valueChanged}
          onComplete={complete}
          showNavigationButtons="none"
          clearInvisibleValues="none"
          onCompleting={beforeComplete}
          onCurrentPageChanging={pageChanging}
        />
      </SurveyNavigation>
    </div>
  )
  
}

Survey.propTypes = {
  formid: PropTypes.string,
  memberid: PropTypes.string,
  status: PropTypes.string,
  record: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.string
  }),
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }),
  onComplete: PropTypes.func.isRequired
}

export default withDataProvider(
  translate(Survey)
)
