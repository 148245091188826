import React from 'react'
import PropTypes from 'prop-types'

import {
  Create, TabbedForm, FormDataConsumer, Query,
  ReferenceInput, SelectInput, AutocompleteInput, required, translate
} from 'react-admin'

import ResponsesTitle from '../ResponsesTitle'

import { TranslatedFormTab  } from '../../pst/pst'

import SurveyInput from '../survey-input'

const AllowedMembersInput = props => {

  const { formid, ...rest } = props

  if(typeof formid !== 'undefined') {

    const payload = {
      pagination: {
        page: 1,
        perPage: 1
      },
      sort: {
        field: 'id',
        order: 'ASC'
      },
      filter: {
        id: formid
      },
    }

    return (
      <Query type="GET_LIST" resource="forms" payload={payload}>
        {
          ({ data, total, loading, error }) => {
            if (loading) { return <p>Loading</p> }
            if (error) { return <p>ERROR</p> }
            if (total > 0) {
              return (
                <ReferenceInput {...rest} resource="responses" filter={{ groups: data[0].canReply }}>
                  <AutocompleteInput optionText="name"/>
                </ReferenceInput>
              )
            }
          }
        }
      </Query>
    )

  } else {

    return (
      <ReferenceInput {...rest} resource="responses">
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    )

  }

}

AllowedMembersInput.propTypes = {
  formid: PropTypes.string
}

const _AdminResponseCreate = props => (
    <Create {...props} title={<ResponsesTitle/>}>
        <TabbedForm>
            <TranslatedFormTab label="pst.responses.formTabs.info">
                <ReferenceInput source="form" reference="forms" validate={required()}>
                  <AutocompleteInput optionText={`name.${props.locale}`} />
                </ReferenceInput>
                <SelectInput
                  source="status"
                  validate={required()}
                  choices={[
                    {
                      id: 'draft',
                      name: 'pst.responses.status.draft'
                    },
                    {
                      id: 'completed',
                      name: 'pst.responses.status.completed'
                    }
                  ]}
                />
                <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (
                      <AllowedMembersInput formid={formData.form} source="member" reference="members" validate={required()} />
                    )
                  }
                </FormDataConsumer>
            </TranslatedFormTab>
            <TranslatedFormTab label="pst.responses.formTabs.reply">
                <FormDataConsumer>
                      {({ formData, ...rest }) =>
                          <SurveyInput
                            source="data"
                            formid={formData.form}
                            memberid={formData.member}
                               {...rest}
                          />
                      }
                </FormDataConsumer>
            </TranslatedFormTab>
        </TabbedForm>
    </Create>
)

export const AdminResponseCreate = translate(_AdminResponseCreate)