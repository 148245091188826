import React from 'react'
import {
  Button, Link, translate,
  CardActions, CreateButton, ExportButton
} from 'react-admin'

import ImportExportIcon from '@material-ui/icons/ImportExport'

const MembersActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate
}) => (
  <CardActions>
      {bulkActions && React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
      }) }
      <Button
        component={Link}
        to="/import"
        color='primary'
        label={translate('pst.importer.importButton')} >
          <ImportExportIcon/>
        </Button>
      <CreateButton basePath={basePath} />
      <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
      />
  </CardActions>
)

export default translate(MembersActions)
