import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import DraftIcon from '@material-ui/icons/MoreHoriz'
import CompletedIcon from '@material-ui/icons/CheckCircle'
import IncompleteIcon from '@material-ui/icons/Close'

const iconStyles = theme => ({
  draftIcon: {
    maxWidth: '20px',
    color: theme.palette.warning.main,
  },
  completeIcon: {
    maxWidth: '20px',
    color: theme.palette.success.main,
  },
  incompleteIcon: {
    maxWidth: '20px',
    color: theme.palette.error.main,
  }
})

export const StyledDraftIcon = withStyles(iconStyles)(props => {
  const { classes } = props
  return <DraftIcon {...props} classes={{root: classes.draftIcon}}/>
})

export const StyledCompletedIcon = withStyles(iconStyles)(props => {
  const { classes } = props
  return <CompletedIcon {...props} classes={{root: classes.completeIcon}}/>
})

export const StyledIncompleteIcon = withStyles(iconStyles)(props => {
  const { classes } = props
  return <IncompleteIcon {...props} classes={{root: classes.incompleteIcon}}/>
})
