import React from "react"
import { Button, translate } from 'react-admin'

import { dataProvider } from '../../../providers/providers'
import { downloadJSON } from '../../pst/pst'

import SaveIcon from '@material-ui/icons/Save'

const slugify = str => {
  const newStr = str.toLowerCase()
        .trim()
        .replace(/[^\w- ]+/g,'')
        .replace(/ +|_/g,'-')
  return newStr
}


const ExportResponsesButton = props => {
  
  const fetchResponses = () => {
        
    const ids = props.record && props.record.id ? [props.record.id] : props.selectedIds
        
    const responses = ids.map(id => {
      
      return dataProvider('EXPORT', 'forms', {
        id: id
      })

    })

    Promise.all(responses).then(results => {
      results.forEach(result => {
        const json = JSON.stringify(result.data)
        const name = result.data.form.name.en
        downloadJSON(json, slugify(name))
      })
    })
    
  }
  
  return (
    <Button
      color="primary"
      onClick={fetchResponses}
      label={props.translate('pst.forms.exportResponses')}>
        <SaveIcon />
    </Button>
  )
  
}

export default translate(ExportResponsesButton)
