import React, { Fragment } from "react"

import {Card, CardContent, Typography} from '@material-ui/core'

import { Title, WithPermissions, translate } from 'react-admin'

import Importer from './Importer'
import JSONInput from 'react-json-editor-ajrm'

import { getUser } from '../pst/pst'


const JSONExample = () => {
  const sample = [
    {
      "id": "5e7768b7e4387c582a9e2800",
      "variables": {
        "locale": "de",
        "region": "de",
        "canton": "be"
      }
    },
    {
      "id": "5e7768b7e4387c582a9e2801",
      "variables": {
        "locale": "de",
        "region": "de",
        "canton": "zh"
      }
    }
  ]

  return (
    <JSONInput
      theme='light_mitsuketa_tribute'
      height='auto'
      width='100%'
      placeholder={sample}
      viewOnly={true}
      confirmGood={false}
    />
  )
}


const ImporterPage = props => {
  
  const { translate, permissions } = props

  let user
  try {
    user = getUser()
  } catch (e) {
    console.warn(e)
  }
  
  return(
    <Card>
      <Title title={translate('pst.importer.title')} />
      <CardContent>
        {
          permissions === 'admin' ? (
            <Fragment>
              <div style={{marginBottom: 50}}>
                <Typography variant="body1" paragraph>{translate('pst.importer.importUserVariables')}</Typography>
                <JSONExample />
              </div>
              <Importer />
            </Fragment>
          ) : (
            <Typography variant="body1">{translate('pst.backend.error.auth.unauthorized')}</Typography>
          )
        }
      </CardContent>
    </Card>
  )
  
}

const ImporterPageWithPermissions = ({ location, translate }) => (
  <WithPermissions
    // location is not required but it will trigger a new permissions check if specified when it changes
    location={location}
    render={ ({ permissions }) => <ImporterPage permissions={permissions} translate={translate} /> }
  />
)

export default translate(ImporterPageWithPermissions)