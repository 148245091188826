import React from 'react'

import {
  Create, TabbedForm,
  TextInput, ReferenceArrayInput, AutocompleteArrayInput, BooleanInput, SelectInput, required
} from 'react-admin'

import MembersTitle from './MembersTitle'

import { TranslatedFormTab } from '../../pst/pst'

import VariablesEditor from './MembersVariablesEditor'

export const MemberCreate = props => (
    <Create {...props} title={<MembersTitle />}>
        <TabbedForm>
            <TranslatedFormTab label="pst.members.formTabs.info">
                <TextInput source="name" validate={required()} />
                <TextInput source="email" type="email" validate={required()}/>
                <SelectInput
                  source="role"
                  validate={required()}
                  choices={[
                    {
                      id: 'admin',
                      name: 'pst.members.roles.admin'
                    },
                    {
                      id: 'member',
                      name: 'pst.members.roles.member'
                    }
                  ]}
                />
                <ReferenceArrayInput source="groups" reference="groups" filter={{archived: false}} >
                  <AutocompleteArrayInput optionText="label" />
                </ReferenceArrayInput>
                <BooleanInput source="active"/>
            </TranslatedFormTab>
            <TranslatedFormTab label="pst.members.formTabs.surveyVariables">
                <VariablesEditor source="variables" defaultValue={'{"locale":"en"}'}/>
            </TranslatedFormTab>
        </TabbedForm>
    </Create>
)
