import React from 'react'

import {
  List, Edit, Create, Datagrid, SimpleForm,
  TextField, EditButton,
  TextInput, DisabledInput, BooleanInput, required
} from 'react-admin'

import GroupsTitle from "./GroupsTitle"
import TabbedDatagrid from './GroupsTabbedDatagrid'

export const GroupList = props => (
  <List {...props} exporter={false}
    filterDefaultValues={{ archived: false }}
    sort={{ field: 'label', order: 'ASC' }}
  >
    <TabbedDatagrid />
  </List>
)

export const GroupEdit = props => (
  <Edit {...props} title={<GroupsTitle />}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="label" validate={required()} />
      <BooleanInput source="archived"/>
    </SimpleForm>
  </Edit>
)

export const GroupCreate = props => (
  <Create {...props} title={<GroupsTitle />}>
    <SimpleForm>
      <TextInput source="label" validate={required()} />
      <BooleanInput source="archived"/>
    </SimpleForm>
  </Create>
)
