import React from 'react'
import PropTypes from 'prop-types'

import { translate } from 'react-admin'
import { Tab } from '@material-ui/core'

const TranslatedListTabComponent = props => {
  
  const { translate, label, key, ...rest } = props
  
  return (
    <Tab
        {...rest}
        key={key}
        label={translate(label)}
        value={props.value}
    />
  )

}

TranslatedListTabComponent.propTypes = {
  key: PropTypes.any,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
}

export const TranslatedListTab = translate(TranslatedListTabComponent)
