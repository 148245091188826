import React from "react"
import PropTypes from 'prop-types'

import { Button, translate } from 'react-admin'
import { Typography } from '@material-ui/core'

import ClearButton from './ClearButton'

import ImportExportIcon from '@material-ui/icons/ImportExport'


const Upload = function (props) {

  const { translate } = props

  const disabled = !(window.File && window.FileReader && window.FileList && window.Blob)
  const file = props.file
  const fileName = file && escape(file.name)

  const fileType = (file && file.type) || 'n/a'
  const fileLastModifiedDate = (file && file.lastModifiedDate) ? file.lastModifiedDate.toLocaleString() : 'n/a'

  const fileMeta = translate('pst.importer.fileMeta', {
    type: fileType,
    size: file && file.size,
    lastModified: fileLastModifiedDate
  })

  /*
  const fileMeta = file && (
    '(' + fileType +') - '
    + file.size + ' bytes - last modified on the '
    + fileLastModifiedDate
  )*/



  // If all the File APIs are supported.
  if (disabled) {

    return (
      <Typography variant="body2" color="error">
        { translate('pst.importer.fileApiNotSupported') }
      </Typography>
    )

  } else {

    if (props.file) {

      return (
        <span>

          <ClearButton onClick={ props.onClear } color="inherit" label={ translate('pst.importer.clear') } />
          <Typography variant="body2" color="textSecondary" style={ { display: 'inline', marginLeft: '10px' } }><strong>{ fileName }</strong> { fileMeta }</Typography>
        </span>
      )

    } else {

      return (
        <span>
          <input
            accept="application/json"
            style={ { display: 'none' } }
            id="button-file"
            type="file"
            onChange={ props.onChange }
            value={ props.value }
          />
          <label htmlFor="button-file">
            <Button
              color="primary"
              label={ translate('pst.importer.loadFile') }
              variant="outlined"
              component="span"
            >
              <ImportExportIcon />
            </Button>
          </label>
        </span>
      )

    }

  }

}

Upload.propTypes = {
  file: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default translate(Upload)
