import React from 'react'

import { 
  List, Datagrid, TextField, translate
} from 'react-admin'

import { ResponseButtons } from '../ResponseButtons'

const _MemberFormList = props => (
    <List {...props}
      filterDefaultValues={{ status: 'published' }}
      sort={{ field: 'id', order: 'ASC' }}
      bulkActionButtons={false}
      exporter={false}
      actions={null}
    >
      <Datagrid>
        <TextField source={`name.${props.locale}`} label="pst.forms.listName" />
        <ResponseButtons {...props} />
      </Datagrid>
    </List>
)

export const MemberFormList = translate(_MemberFormList)