import englishMessages from 'ra-language-english'
import frenchMessages from 'ra-language-french'
import italianMessages from 'ra-language-italian'
import germanMessages from 'ra-language-german'

import en from '../translation/en'
import fr from '../translation/fr'
import it from '../translation/it'
import de from '../translation/de'

const messages = {
    en: {...englishMessages, ...en},
    fr: {...frenchMessages, ...fr},
    it: {...italianMessages, ...it},
    de: {...germanMessages, ...de}
}

export const i18nProvider = locale => messages[locale]
