import React, { Fragment } from 'react'

import {
  List, Filter,
  TextInput, ReferenceInput, AutocompleteInput, SelectInput,
  BulkDeleteButton
} from 'react-admin'

import { downloadJSON } from '../../pst/pst'

import TabbedDatagrid from './MembersTabbedDatagrid'
import BulkAddToGroupsButton from './BulkAddToGroupsButton'
import MembersActions from './MembersActions'

const exporter = responses => {  
  downloadJSON(JSON.stringify(responses), 'members')
}

const MemberBulkActionButtons = props => (
  <Fragment>
      <BulkAddToGroupsButton {...props} />
      <BulkDeleteButton {...props} />
  </Fragment>
)

const MembersFilter = props => (
    <Filter {...props}>
      <TextInput label="ra.action.search" source="q" alwaysOn />
      <SelectInput
        alwaysOn
        source="role"
        choices={[
          {
            id: 'admin',
            name: 'pst.members.roles.admin'
          },
          {
            id: 'member',
            name: 'pst.members.roles.member'
          }
        ]}
      />
      <ReferenceInput
        source="groups"
        reference="groups"
        filter={{ archived: false }}
        alwaysOn
        style={{minWidth: '160px'}}>
          <AutocompleteInput optionText="label" />
      </ReferenceInput>
    </Filter>
)

export const MemberList = props => (
    <List
      {...props}
      filterDefaultValues={{ role: "member", active: true }}
      sort={{ field: 'id', order: 'ASC' }}
      exporter={exporter}
      actions={<MembersActions/>}
      bulkActionButtons={<MemberBulkActionButtons/>}
      filters={<MembersFilter/>} >
        <TabbedDatagrid />
    </List>
)
