export const SurveyTheme = {  
  '$header-background-color': '#0099CB',
  '$body-container-background-color': '#eee',
  '$main-color': '#0099CB',
  '$main-hover-color': '#005e7c',
  '$body-background-color': '#fafafa',
  '$inputs-background-color': 'white',
  '$text-color': '#4a4a4a',
  '$header-color': '#6d7072',
  '$border-color': '#e7e7e7',
  '$error-color': '#ed5565',
  '$error-background-color': '#fd6575',
  '$progress-text-color': '#9d9d9d',
  '$disable-color': '#dbdbdb',
  '$disabled-label-color': 'rgba(64, 64, 64, 0.5)',
  '$slider-color': 'white',
  '$disabled-switch-color': '#9f9f9f',
  '$disabled-slider-color': '#cfcfcf',
}
