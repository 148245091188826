import { env } from '../components/pst/pst'

import { AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin'

import { getRole } from '../components/pst/pst'

import jwt from 'jsonwebtoken'

export const refreshToken = () => {

  let token = sessionStorage.getItem('token')

  const request = new Request(env.REACT_APP_API_URL+'/login/refresh', {
    method: 'POST',
    headers: new Headers({  'Content-Type': 'application/json',
                            'authorization' : token
                          }),
  })

  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        return Promise.reject(response.statusText)
      }
      return response.json()
    })
    .then(({ token }) => {
      sessionStorage.setItem('token', token)
      return Promise.resolve()
    })

}

export const authProvider = (type, params) => {
  
  if (type === AUTH_LOGIN) {
    
      const { username, password } = params
        
      const request = new Request(env.REACT_APP_API_URL+'/login', {
          method: 'POST',
          body: JSON.stringify({
            user :  {
              email: username,
              pass: password
            }
          }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      })

      return fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText)
              }
              return response.json()
          })
          .then(({ token }) => {
              sessionStorage.setItem('token', token)
          })
    
  }
  if (type === AUTH_LOGOUT) {
    sessionStorage.removeItem('token')
      return Promise.resolve()
  }
  if (type === AUTH_ERROR) {
      if(params.status === 401){
        sessionStorage.removeItem('token')
        return Promise.reject()
      }
      return Promise.resolve()
  }
  if (type === AUTH_CHECK) {
    
      let token = sessionStorage.getItem('token')

      const decodedToken = jwt.decode(token)

      const currentTime = Date.now() / 1000

      // if the token expires in less than 20 minutes refresh it (with an expiration time of 30min, this leads to 1 refresh max every 10min)
      if( decodedToken.exp - currentTime < 20 * 60 ) {

        return refreshToken()

      } else {

        return Promise.resolve()

      }

  }
  if (type === AUTH_GET_PERMISSIONS) {
    
    const role = getRole()
    
    return role
    
  }
  
  return Promise.resolve()
  
}
