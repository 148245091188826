import React from 'react'

import { Button } from 'react-admin'

import ClearIcon from '@material-ui/icons/Clear'
import { withStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
})

const ClearButton = ({ classes, ...props }) => (
    <Button
        className={classes.deleteButton}
        {...props}
    >
      <ClearIcon/>
    </Button>
)

export default withStyles(styles)(ClearButton)