// Generic import
import React from 'react'
import { env, getLocale, localeSaga, ReactAdminTheme, LocaleMenu } from './components/pst/pst'
import { Route } from 'react-router-dom'

// ReactAdmin providers
import {
  dataProvider,
  authProvider,
  i18nProvider
}  from './providers/providers'

import { Admin, Resource, Login, AppBar, Layout, UserMenu, WithPermissions } from 'react-admin'

import ImporterPage from './components/importer/ImporterPage'
import Dashboard from './components/dashboard/Dashboard'

import {
  MemberList,
  MemberEdit,
  MemberCreate
} from './components/admin/members/Members'

import {
  GroupList,
  GroupEdit,
  GroupCreate
} from './components/admin/groups/Groups'

import {
  AdminFormList,
  MemberFormList,
  FormEdit,
  FormCreate
} from './components/forms/Forms'

import {
  AdminResponseList,
  AdminResponseEdit,
  AdminResponseCreate,
  MemberResponseList,
  MemberResponseEdit,
  MemberResponseShow
} from './components/responses/Responses'

import GroupIcon from '@material-ui/icons/People'
import MemberIcon from '@material-ui/icons/Person'
import FormIcon from '@material-ui/icons/Assignment'
import ResponseIcon from '@material-ui/icons/Poll'

// SCSS
import './scss/App.scss'

console.info(`stats-client version : ${env.REACT_APP_VERSION}`)

const CustomLoginPage = props => {
  
  const photos = [
    'ojVMh1QTVGY',
    'KGQhWwB0tDg',
    'zyVDQb4VeUA'
  ]
  
  const num = Math.floor( (new Date()).getSeconds() % photos.length )
  
  return (
    <Login {...props} backgroundImage={`https://source.unsplash.com/${photos[num]}/1600x900`} className="petzi-login" />
  )
}

const CustomUserMenu = props => (
  <UserMenu {...props}>
      <LocaleMenu />
  </UserMenu>
);

const CustomAppBar = props => (
  <AppBar {...props} userMenu={<CustomUserMenu />} />
)

const _CustomLayout = props => {

  const dashboard = props.permissions === "admin" ? props.dashboard : null

  return (
    <Layout {...props} appBar={CustomAppBar} dashboard={dashboard} />
  )
}


const CustomLayout = props => (
  <WithPermissions
      render={
        ({permissions}) => (<_CustomLayout {...props} permissions={permissions} />)
      }
  />
)


const CustomRoutes = [
  <Route exact path="/import" component={ImporterPage} />
]

const App = props => {
  
    /*constructor() {
    super()
    //this.state = { dataProvider: null }
    }
  
    componentDidMount() {
      //this.setState( dataProvider )
    }

    render() {
      const { dataProvider } = this.state

      if (!dataProvider) {
          return <div>Loading</div>
      }
    }*/
        
  return (
    <Admin
      theme={ReactAdminTheme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      locale={getLocale()}
      loginPage={CustomLoginPage}
      appLayout={CustomLayout}
      customRoutes={CustomRoutes}
      customSagas={[ localeSaga ]}
      dashboard={Dashboard} >
      {
        permissions => [
          <Resource
            name="groups"
            list={permissions === 'admin' ? GroupList : null}
            edit={permissions === 'admin' ? GroupEdit : null}
            create={permissions === 'admin' ? GroupCreate : null}
            icon={GroupIcon} />,
          <Resource
            name="members"
            list={permissions === 'admin' ? MemberList : null}
            edit={permissions === 'admin' ? MemberEdit : null}
            create={permissions === 'admin' ? MemberCreate : null}
            icon={MemberIcon} />,
          <Resource
            name="forms"
            list={permissions === 'admin' ? AdminFormList : MemberFormList}
            edit={permissions === 'admin' ? FormEdit : null}
            create={permissions === 'admin' ? FormCreate : null}
            icon={FormIcon} />,
          <Resource
            name="responses"
            list={permissions === 'admin' ? AdminResponseList : MemberResponseList}
            edit={permissions === 'admin' ? AdminResponseEdit : MemberResponseEdit}
            create={permissions === 'admin' ? AdminResponseCreate : null}
            show={MemberResponseShow}
            icon={ResponseIcon} />
        ]
      }
      
    </Admin>
  )
  
}

export default App
