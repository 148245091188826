import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core'

import ConfirmIcon from '@material-ui/icons/Check'

import { Button, translate } from 'react-admin'

const SurveyErrorPopup = (props) => {

  const { open, onClose, validated, survey, translate } = props

  const pagesWithErrors = []

  Object.keys(validated).forEach((pageId) =>  {
    if (!validated[pageId]) {
      pagesWithErrors.push({
        id: parseInt(pageId),
        title: survey.visiblePages[pageId].title
      })
    }
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{translate('pst.responses.survey.errorPopup.title', { smart_count: pagesWithErrors.length })}</DialogTitle>
      <DialogContent style={{minWidth: 300}}>
        <DialogContentText id="alert-dialog-description" component="div">
          <p>{translate('pst.responses.survey.errorPopup.content')}</p>
          <ul>
            {
              pagesWithErrors.map((page) => (<li key={page.id}>{`${page.id+1}. ${page.title}`}</li>))
            }
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          size="medium"
          label="pst.responses.survey.errorPopup.confirm"
        >
          <ConfirmIcon />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SurveyErrorPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  validated: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
}

export default translate(SurveyErrorPopup)