import React from 'react'

import {
  translate
} from 'react-admin'

import { JSONEditorInput } from '../../pst/pst'

const schema = {
  type: 'object',
  required: ['locale'],
  additionalProperties: true,
  properties: {
    locale: {
      type: 'string',
      minLength: 2,
      maxLength: 2
    }
  }
}

const VariablesEditor = props => {
  
    const {translate, ...rest} = props
    
    // From JSON editor to record
    const parseVariables = function (v) {

      return JSON.parse(v)

    }

    // from record to JSON editor
    const formatVariables = function (v) {
      
      return JSON.stringify(v)

    }
        
    return (
      <JSONEditorInput
        {...props}
        label={translate('pst.members.variablesEditor.jsonVariables')}
        format={formatVariables}
        parse={parseVariables}
        schema={schema}
      />
    )

}

export default translate(VariablesEditor)
