import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { InputLabel, Select, MenuItem } from '@material-ui/core'

import {
  translate, UPDATE,
  changeLocale as changeLocaleAction,
} from 'react-admin'

import { dataProvider, refreshToken } from '../../../providers/providers'

import { getUser } from '../pst'

const LocaleSwitcherComponent = props => {
  
    const {changeLocale, translate, locale, style} = props
        
    const handleChange = (event) => {
      
      const newLocale = event.target.value
      
      dataProvider(UPDATE, 'members', {
        id: getUser().id,
        data: {
          'variables.locale': newLocale
        }
      }).then(
        (result) => {
          changeLocale(newLocale)
        }
      ).then(refreshToken)
    }

    const finalStyles = {
      container : {
        display: 'flex',
        flexDirection: 'column',
        alignItems:'stretch',
        padding: 20,
        marginTop: 30,
        ...style
      },
      label: {
        display: 'block'
      },
      select: {
        marginTop: '20px', 
        marginBottom: '15px'
      }
    }
      
    return (
      <div style={finalStyles.container}>
        <InputLabel htmlFor="language" style={finalStyles.label}>{translate('pst.localeMenu.language')}</InputLabel>
        <Select
          value={locale}
          onChange={handleChange}
          inputProps={{
            name: 'language',
            id: 'language',
          }}
          style={finalStyles.select}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="fr">Français</MenuItem>
          <MenuItem value="it">Italiano</MenuItem>
          <MenuItem value="de">Deutsch</MenuItem>
        </Select>
      </div>
    )

}

export const LocaleSwitcher = translate(
  connect(undefined, {
    changeLocale: changeLocaleAction
  })(LocaleSwitcherComponent)
)

LocaleSwitcher.propTypes = {
  style: PropTypes.object
}