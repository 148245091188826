import React, {
  Fragment,
  useState,
  useEffect
} from "react"

import {
  Button,
  withDataProvider,
  translate,
  GET_LIST,
  UPDATE_MANY
} from 'react-admin'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  Chip,
  MenuItem
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

import SaveIcon from '@material-ui/icons/Save'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import CancelIcon from '@material-ui/icons/Close'

const styles = theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  cancelButton: {
    color: theme.palette.error.main,
    '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
            backgroundColor: 'transparent',
        },
      },
  },
})

const ITEM_HEIGHT = 48,
      ITEM_PADDING_TOP = 8,
      MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      }


const _MultipleSelect = props => {

  const { classes, id, possibleValues, theme, value, ...rest } = props

  return (
    <Select
      {...rest}
      value={value}
      theme={theme}
      multiple={true}
      input={<Input id={id} />}
      renderValue={value => (
        <div className={classes.chips}>
          {
            value.map(id => {
              const value = possibleValues.find(value => value.id === id)
              return(
                <Chip key={value.id} label={value.label} className={classes.chip} />
              )
            })
          }
        </div>
      )}
      MenuProps={MenuProps}
    >
    {possibleValues.map(possibleValue => (
      <MenuItem
        key={possibleValue.id}
        value={possibleValue.id}
      >
        {possibleValue.label}
      </MenuItem>
    ))}
    </Select>
  )

}

const MultipleSelect = withStyles(styles, { withTheme: true })(_MultipleSelect)


const BulkAddToGroupsButton = props => {

    const { translate, dataProvider, selectedIds, classes } = props

    const [isOpen, setIsOpen] = useState(false)
    const [isError, setIsError] = useState(false)
    const [groups, setGroups] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])

    // equivalent to componentDidMount
    useEffect(() => {
      
      const options = {
        pagination: {
          page: 1,
          perPage: 10000
        },
        sort: {
          field: 'label',
          order: 'ASC'
        },
        filter: {
          archived: false
        }
      }

      dataProvider(GET_LIST, 'groups', options, {
        onSuccess: {
          callback: response => {
            setGroups(response.data)
          }
        },
        onFailure: {
          notification: { body: `pst.members.bulkAddToGroups.errors.loadGroups`, level: 'warning' }
        }
      })

    }, [])

    const handleClick = () => {
      setIsOpen(true)
    }

    const handleChange = event => {
      if (event.target.value.length<1) {
        setIsError(translate('pst.members.bulkAddToGroups.errors.noGroup'))
      } else {
        setIsError(false)
      }
      setSelectedGroups( event.target.value )
    }

    const handleDialogClose = () => {
      setSelectedGroups([])
      setIsError(false)
      setIsOpen(false)
    }

    const handleConfirm = () => {

      if (selectedGroups.length) {

        const groupsRequest = {
          $addToSet: {
            groups: {
              $each: selectedGroups
            }
          }
        }

        dataProvider(UPDATE_MANY, 'members', { ids: selectedIds, data: groupsRequest }, {
          onSuccess: {
            notification: { body: 'pst.members.bulkAddToGroups.success', level: 'info' },
            refresh: true,
          },
          onFailure: {
            notification: { body: "pst.members.bulkAddToGroups.errors.updateMembers", level: 'warning' }
          }
        })

        handleDialogClose()

      }

    }

    const saveEnabled = !isError && selectedGroups.length

    return (
      <Fragment>
          <Button label={translate('pst.members.bulkAddToGroups.button')} onClick={handleClick}>
            <GroupAddIcon />
          </Button>
          <Dialog
            open={isOpen}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{translate('pst.members.bulkAddToGroups.title')}</DialogTitle>
            <DialogContent>
              <DialogContentText style={{marginBottom: 20}}>
                {translate('pst.members.bulkAddToGroups.text')}
              </DialogContentText>
              <FormControl
                style={{
                  minWidth: 120
                }}
                error={isError !== false}
              >
                <InputLabel htmlFor="goups-select">{translate('resources.members.fields.groups')}</InputLabel>
                <MultipleSelect
                  autoFocus
                  fullWidth
                  value={selectedGroups}
                  onChange={handleChange}
                  possibleValues={groups}
                  id="groups-select"
                />
                {
                  isError &&
                  <FormHelperText>{isError}</FormHelperText>
                }
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDialogClose}
                color="inherit"
                variant="text"
                size="medium"
                className={classes.cancelButton}
                label="pst.members.bulkAddToGroups.cancel"
              >
                <CancelIcon />
              </Button>
              <Button
                onClick={handleConfirm}
                variant="contained"
                color="primary"
                size="medium"
                label="pst.members.bulkAddToGroups.save"
                disabled={!saveEnabled}
              >
                <SaveIcon />
              </Button>
            </DialogActions>
          </Dialog>
      </Fragment>
    )
}

export default withDataProvider(
  withStyles(styles)( translate(BulkAddToGroupsButton) )
)
