import React from 'react'
import PropTypes from 'prop-types'

import { translate } from 'react-admin'
import { Chip } from '@material-ui/core'

const TranslatedChipComponent = props => {
  
  let translateFunc
  
  const {label, translate, translateParameters, ...rest} = props
  
  if (translateParameters) {
    translateFunc = translate(label, translateParameters)
  } else {
    translateFunc = translate(label)
  }
  
  return (
    <Chip {...rest}
      label={translateFunc}
    />
  )

}

TranslatedChipComponent.propTypes = {
  label: PropTypes.string.isRequired,
  translateParameters: PropTypes.object
}

export const TranslatedChip = translate(TranslatedChipComponent)
