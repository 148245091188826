import { env } from '../components/pst/pst'
import { fetchUtils } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'
import addExportFeature from './addExportFeature'

// HTTP REST
const httpClient = (url, options = {}) => {
    const token = sessionStorage.getItem('token')
  
    options.user = {
        authenticated: token ? true : false,
        token: token ? token : ""
    }
    return fetchUtils.fetchJson(url, options)
}

const originalDataProvider = simpleRestProvider(env.REACT_APP_API_URL+'/api', httpClient)

export const dataProvider = addExportFeature(originalDataProvider)
