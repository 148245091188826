import React from 'react'

import {
  translate, GET_ONE
} from 'react-admin'

import { dataProvider } from '../../providers/providers'

const ResponsesTitle = ({ record, translate, locale }) => {
  
  const [title, changeTitle] = React.useState('')
    
  if (record && record.form) {
    
    dataProvider(GET_ONE, 'forms', {id: record.form}).then(
      (result) => {
        changeTitle(result.data.name[locale])
      }
    )
    
  }
  
  if (title === '') {
    return <span>{translate( 'pst.responses.title.create')}</span>
  } else {
    return <span>{translate( 'pst.responses.title.edit', {form: title} )}</span>
  }

}

export default translate(ResponsesTitle)
