import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Field } from 'redux-form'
import Survey from './survey'

import { UPDATE } from 'react-admin'

const COMPLETE = 'COMPLETE'

const completeAction = (id, data, basePath, redirectTo) => {
  
  const action = {
      type: COMPLETE,
      payload: { id, data: { ...data, status: 'completed' } },
      meta: {
        resource: 'responses',
        fetch: UPDATE,
        onSuccess: {
          notification: {
            body: 'pst.responses.survey.completion.success',
            level: 'info'
          },
          basePath
        },
        onFailure: {
          notification: {
            body: 'pst.responses.survey.completion.error',
            level: 'warning'
          },
        },
      }
  }
  
  if (redirectTo) {
    action.meta.onSuccess.redirectTo = redirectTo
  }
  
  return action
}

const SurveyInput = (props) => {
  
  const {complete, record, source, basePath} = props

  const handleComplete = data => {
    complete(record.id, { ...record, data }, basePath, '/#/forms')
  }
  
  return (
    <Field
      {...props}
      name={source}
      component={Survey}
      onComplete={handleComplete}
    />
  )
  
}

SurveyInput.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.string
  }),
  basePath: PropTypes.string,
}

export default connect(null, {
  complete: completeAction
})(SurveyInput)
