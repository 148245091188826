import React from 'react'

import {
  List, Datagrid, Filter,
  ReferenceField, TextField,
  ReferenceInput, AutocompleteInput, SelectInput,
  EditButton, translate
} from 'react-admin'

import { IconSelectField, downloadJSON, StyledDraftIcon, StyledCompletedIcon } from '../../pst/pst'

const exporter = responses => {  
  downloadJSON(JSON.stringify(responses), 'responses')
}

const _ResponsesFilter = props => (
    <Filter {...props}>
        <SelectInput
          alwaysOn
          source="status"
          choices={[
            {
              id: 'draft',
              name: 'pst.responses.status.draft'
            },
            {
              id: 'completed',
              name: 'pst.responses.status.completed'
            }
          ]}
        />
        <ReferenceInput source="member" reference="members" alwaysOn>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="form" reference="forms" alwaysOn>
          <AutocompleteInput optionText={`name.${props.locale}`}/>
        </ReferenceInput>
    </Filter>
)

const ResponsesFilter = translate(_ResponsesFilter)

const _AdminResponseList = props => (
    <List {...props} sort={{ field: 'id', order: 'ASC' }} exporter={exporter} filters={<ResponsesFilter/>}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="form" reference="forms"><TextField source={`name.${props.locale}`} /></ReferenceField>
            <ReferenceField source="member" reference="members"><TextField source="name" /></ReferenceField>
            <IconSelectField
              source="status"
              choices={[
                {
                  id: 'draft',
                  name: 'pst.responses.status.draft',
                  icon: <StyledDraftIcon />
                },
                {
                  id: 'completed',
                  name: 'pst.responses.status.completed',
                  icon: <StyledCompletedIcon />
                }
              ]}
            />
            <EditButton/>
        </Datagrid>
    </List>
)

export const AdminResponseList = translate(_AdminResponseList)