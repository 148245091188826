import { createMuiTheme } from '@material-ui/core/styles'


export const ReactAdminTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0099CB',
    },
    secondary: {
      main: '#fafafa',
    },
    background: {
      default: '#eee',
      paper: '#fafafa'
    },
    success: {
      main: '#18d86b'
    },
    warning: {
      main: '#ffa842'
    }
  },
  overrides : {
    MuiAppBar: {
      root: {
        color: '#444444 !important',
        '& button:hover': {
          color: '#0099CB'
        }
      }
    }
  }
})