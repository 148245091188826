import React, { Fragment } from 'react'
import {
  Datagrid,
  TextField, ReferenceField,
  ShowButton, EditButton, translate
} from 'react-admin'
import { Divider, Tabs, Tooltip } from '@material-ui/core'

import { TranslatedListTab } from '../../pst/pst'

const ListButtons = props => {
  const { record, parentProps } = props

  const translate = parentProps.translate
  const formRecord = record
  const responseRecord = parentProps.record

  if(formRecord.status === 'completed') {
    return (
      <Tooltip title={translate('pst.responses.formClosed')}>
        <span style={{display: 'inline-block'}}>
          <ShowButton { ...parentProps } />
        </span>
      </Tooltip>
    )
  } else if (responseRecord.status === 'completed') {
    return <ShowButton { ...parentProps } />
  } else if (responseRecord.status === 'draft') {
    return <EditButton { ...parentProps } />
  }
}

const PropsWrapper = props => {
  return (
    <ReferenceField {...props} source="form" reference="forms" linkType={ false }>
      <ListButtons parentProps={props} />
    </ReferenceField>
  )
}

const DatagridContent = props => {

  return (
    <Datagrid { ...props } >
      <ReferenceField source="form" reference="forms" linkType={ false }>
        <TextField source={ `name.${ props.locale }` } />
      </ReferenceField>
      <TextField source="id" />
      <PropsWrapper translate={props.translate} />
    </Datagrid>
  )

}

class TabbedDatagrid extends React.Component {
  tabs = [
    { id: 'draft', name: 'pst.responses.listTabs.draft' },
    { id: 'completed', name: 'pst.responses.listTabs.completed' },
  ]

  state = { draft: [], completed: [] }

  static getDerivedStateFromProps (props, state) {
    if (props.ids !== state[ props.filterValues.status ]) {
      return { ...state, [ props.filterValues.status ]: props.ids }
    }
    return null
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props
    setFilters({ ...filterValues, status: value })
  }

  render () {
    const { classes, filterValues, ...props } = this.props
    return (
      <Fragment>
        <Tabs
          fullWidth
          centered
          value={ filterValues.status }
          indicatorColor="primary"
          onChange={ this.handleChange }
        >
          { this.tabs.map(choice => (
            <TranslatedListTab
              key={ choice.id }
              label={ choice.name }
              value={ choice.id }
            />
          )) }
        </Tabs>
        <Divider />
        <div>
          <DatagridContent { ...props } ids={ this.state[ filterValues.status ] } />
        </div>
      </Fragment>
    )
  }
}

export default translate(TabbedDatagrid)
