import React, {Fragment} from 'react'

import { 
  List, Filter,
  TextInput, ReferenceInput, AutocompleteInput,
  BulkDeleteButton
} from 'react-admin'

import TabbedDatagrid from './AdminFormsTabbedDatagrid'
import ExportResponsesButton from './ExportResponsesButton'

const FormBulkActionButtons = props => (
    <Fragment>
        <ExportResponsesButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
)

const FormsFilter = props => (
    <Filter {...props}>
      <TextInput label="ra.action.search" source="q" alwaysOn />
      <ReferenceInput source="canReply" reference="groups" alwaysOn style={{minWidth: '160px'}} filter={{archived: false}}>
          <AutocompleteInput optionText="label" />
      </ReferenceInput>
    </Filter>
)

export const AdminFormList = props => (
    <List {...props}
      filterDefaultValues={{ status: 'draft' }}
      sort={{ field: 'id', order: 'ASC' }}
      bulkActionButtons={<FormBulkActionButtons/>}
      exporter={false}
      filters={<FormsFilter />}
    >
        <TabbedDatagrid />
    </List>
)
