import React from "react"
import PropTypes from 'prop-types'

import {
  Button, showNotification, translate,
  UPDATE
} from 'react-admin'
import { connect } from 'react-redux'

import ImportExportIcon from '@material-ui/icons/ImportExport'

const VARIABLES_SUBMIT = 'VARIABLES_SUBMIT'

const variablesSubmitAction = (id, data, basePath, handleResult, done) => {

  const action = {
    type: VARIABLES_SUBMIT,
    payload: { id, data: { ...data } },
    meta: {
      resource: 'members',
      fetch: UPDATE,
      onSuccess: {
        callback: handleResult(true, id, done, false),
        basePath
      },
      onFailure: {
        callback: handleResult(false, id, done, 'pst.importer.requestError'),
      },
    }
  }

  return action
}

function SubmitButton (props) {

  const { showNotification, variablesSubmit, absoluteImport, handleResult, onSubmit, translate } = props

  const handleClick = e => {

    const members = props.data

    // Validation on the whole array

    try {

      if( !Array.isArray(members) ) {
        throw new Error('pst.importer.notifications.invalidRoot')
      }

    } catch (e) {
      showNotification(e.message, 'warning')
    }

    // Validation on each member

    try {

        members.forEach( (member, index) => {

          let error = false

          // check if the variables property exists
          if(!member.variables) {
            error = 'pst.importer.errors.missingVariables'
          }

          // check if the id property exists
          if(!member.id) {
            error = 'pst.importer.errors.missingId'
          }

          // show notification and trigger loading only before uploading first member variables
          if(index === 0) {
            
            showNotification('pst.importer.notifications.pleaseWait', 'info')

            if (typeof onSubmit === 'function') {
              onSubmit()
            }

          }

          // set "done" to true if this is our last member in the list
          const done = index === (members.length-1)

          if (!error) {

            // if there is no error

            // Prepare variables for upload
            let variables = {}

            // if the variables are set as array, transform to object
            if( Array.isArray(member.variables) ){
              member.variables.forEach(variable => {
                if(typeof variable.name !== 'undefined') {
                  variables[variable.name] = variable.value || null
                }
              })
            } else {
              variables = member.variables
            }

            // set the payload depending on the import mode
            let update = {}

            if( absoluteImport ) {
              update = { variables }
            } else {
              // convert object to mongoDB dot notation (variable.myVariableName = foo)
              // this prevents mongoDB from erasing variables that are not set
              Object.keys(variables).forEach(variableName => {
                update['variables.'+variableName] = variables[variableName]
              })
            }

            // and finally send the payload
            variablesSubmit(member.id, update, '/', handleResult, done)

          } else {

            // if there are errors

            handleResult(false, member.id || "Index : " +index, done, error)()

          }

        })

    } catch (e) {

      showNotification(e.message, 'warning')

    }

  }

  return (
     <Button
        color="primary"
        onClick={handleClick}
        label={translate('pst.importer.launchImport')}
        variant="contained"
        disabled={props.disabled}
        >
          <ImportExportIcon/>
      </Button>
  )

}

SubmitButton.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  absoluteImport: PropTypes.bool.isRequired,
  handleResult: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
}

export default translate(
  connect(null, {
    showNotification,
    variablesSubmit: variablesSubmitAction
    //startUndoable: startUndoableAction
  })(SubmitButton)
)
