import { resolveBrowserLocale } from 'react-admin'
import jwt from 'jsonwebtoken'

export const getUser = () => {
  const token = sessionStorage.getItem('token')
  const user = jwt.decode(token)
  if (!user) {
    throw new Error ('No user')
  }
  return user
}

export const getLocale = () => {
  let locale
  try {
    const user = getUser()
    if (!user.variables) {
      throw new Error ('No user variables')
    }
    locale = user.variables.locale
    if(!locale) {
      throw new Error ('No "locale" variable found')
    }
  } catch (e) {
    console.warn(e.message)
    locale = resolveBrowserLocale()
  }
  return locale
}

export const getRole = () => {
  let role
  try {
    const user = getUser()
    if(!user.role) {
      throw new Error ('No user role')
    }
    role = user.role
  } catch (e) {
    console.warn(e.message)
    role = null
  }
  return role
}