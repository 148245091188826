import React from 'react'
import PropTypes from 'prop-types'

import { SelectField, translate } from 'react-admin'

const IconSelectFieldComponent = props => {
  
  const { translate,
          choices,
          ...rest } = props
  
  const newChoices = choices.map(choice => {
    const result = {}
    if (typeof choice.id !== 'undefined') {
      result.id = choice.id
    }
    if (typeof choice.name !== 'undefined') {
      result.name = translate(choice.name)
    }
    if (typeof choice.icon !== 'undefined') {
      result.name = React.cloneElement(
        choice.icon,
        {
          title: result.name,
          alt: result.name
        }
      )
    }
    return result
  })
  
  return (
    <SelectField
      {...rest}
      choices={newChoices}
      translateChoice={false}
    />
  )
  
}

IconSelectFieldComponent.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
    })
  ).isRequired
}

export const IconSelectField = translate(IconSelectFieldComponent)
