import React from 'react'
import { translate } from 'react-admin'

const GroupsTitle = ({record, translate}) => {
  
  if (Object.keys(record).length) {
    return <span>{translate('pst.groups.title.edit', { label: record.label } )}</span>
  } else {
    return <span>{translate('pst.groups.title.create')}</span>
  }
  
}

export default translate(GroupsTitle)
