import React from 'react'
import PropTypes from 'prop-types'

import { FormTab, translate } from 'react-admin'

const TranslatedFormTabComponent = props => {
  
  const {children, translate, label, ...rest} = props
  
  return (
    <FormTab
      {...rest}
      label={translate(label)}
    >
      {children}
    </FormTab>
  )

}

TranslatedFormTabComponent.propTypes = {
  label: PropTypes.string
}

export const TranslatedFormTab = translate(TranslatedFormTabComponent)
