export const SurveyEditorTheme = {
  '$primary-color': '#0099CB',
  '$secondary-color': '#0099CB',
  '$primary-text-color': '#676a6c',
  '$secondary-text-color': '#a7a7a7',
  '$inverted-text-color': '#fafafa',
  '$primary-hover-color': '#005e7c',
  '$selection-border-color': '#4a4a4a',
  '$primary-icon-color': '#3d4d5d',
  '$primary-bg-color': '#eeeeee',
  '$secondary-bg-color': '#fafafa',
  '$primary-border-color': '#fafafa',
  '$secondary-border-color': '#ddd'
}
