import React from "react"
import PropTypes from 'prop-types'

import * as SurveyJSCreator from "survey-creator"
import * as SurveyKo from "survey-knockout"
import "survey-creator/survey-creator.css"

import { getLocale, SurveyTheme, SurveyEditorTheme } from '../../pst/pst'

//import "jquery-ui/themes/base/all.css";
//import "nouislider/distribute/nouislider.css";
//import "select2/dist/css/select2.css";
//import "bootstrap-slider/dist/css/bootstrap-slider.css";

//import "jquery-bar-rating/dist/themes/css-stars.css";
//import "jquery-bar-rating/dist/themes/fontawesome-stars.css";

//import $ from "jquery"
//import "jquery-ui/ui/widgets/datepicker.js";
//import "select2/dist/js/select2.js";
//import "jquery-bar-rating";

//import "icheck/skins/square/blue.css";

//import * as widgets from "surveyjs-widgets";

//widgets.icheck(SurveyKo, $);
//widgets.select2(SurveyKo, $);
//widgets.inputmask(SurveyKo);
//widgets.jquerybarrating(SurveyKo, $);
//widgets.jqueryuidatepicker(SurveyKo, $);
//widgets.nouislider(SurveyKo);
//widgets.select2tagbox(SurveyKo, $);
//widgets.signaturepad(SurveyKo);
//widgets.sortablejs(SurveyKo);
//widgets.ckeditor(SurveyKo);
//widgets.autocomplete(SurveyKo, $);
//widgets.bootstrapslider(SurveyKo);

class SurveyEditor extends React.Component {
  surveyCreator
  
  constructor(props) {
    super(props)
        
    SurveyKo.StylesManager.ThemeColors["default"] = SurveyTheme
    SurveyKo.StylesManager.applyTheme()
    
    SurveyJSCreator.StylesManager.ThemeColors["petzi"] = SurveyEditorTheme
    SurveyJSCreator.StylesManager.applyTheme("petzi")

    //this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    //this.handleClick = this.handleClick.bind(this);
  }
  
  shouldComponentUpdate(){
    return false
  }
  
  componentDidMount(){
    var options = {
      // show the embeded survey tab. It is hidden by default
      showEmbededSurveyTab : false,
      // hide the test survey tab. It is shown by default
      showTestSurveyTab : true,
      // hide the JSON text editor tab. It is shown by default
      showJSONEditorTab : true,
      // show the "Options" button menu. It is hidden by default 
      showLogicTab: true,
      showOptions: true,
      isAutoSave : true,
    }
        
    const locale = getLocale()
    
    SurveyKo.surveyLocalization.defaultLocale = locale
    SurveyJSCreator.localization.currentLocale = locale
    
    this.surveyCreator = new SurveyJSCreator.SurveyCreator(
      this.refs.surveyEditor,
      options
    )
    
    this.surveyCreator.showToolbox = "right";
    //Show property grid in the right container, combined with toolbox
    this.surveyCreator.showPropertyGrid = "right";
    //Make toolbox active by default
    this.surveyCreator.rightContainerActiveItem("toolbox");
    
    if(this.props.input.value){
      this.surveyCreator.text = this.props.input.value
    }
    
    this.surveyCreator.saveSurveyFunc = this.saveMySurvey
  }
  
  // When updating form
  /*componentWillReceiveProps(nextProps){

  }*/
  
  /*handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }*/
  
  render() {
    return (
      <div id="surveyEditor" ref="surveyEditor"/>
    )
  }
  
  saveMySurvey = () => {
    this.props.input.onChange(this.surveyCreator.text)
  }
  
}

SurveyEditor.propTypes = {
  input: PropTypes.object.isRequired,
}

export default SurveyEditor
