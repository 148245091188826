import React from 'react'
import { translate } from 'react-admin'

const FormsTitle = ({ record, translate, locale }) => {

  if (Object.keys(record).length) {
    return <span>{translate('pst.forms.title.edit', { name: record.name[locale] } )}</span>
  } else {
    return <span>{translate('pst.forms.title.create')}</span>
  }

}

export default translate(FormsTitle)
