import React from 'react'

import {
  Edit, SimpleForm, FormDataConsumer, SaveButton, Toolbar,
} from 'react-admin'

import ResponsesTitle from '../ResponsesTitle'

import SurveyInput from '../survey-input'

const ResponseEditToolbar = props => (
  <Toolbar { ...props } >
    <SaveButton label='pst.responses.saveAsDraft' />
  </Toolbar>
)

export const MemberResponseEdit = props => (
  <Edit { ...props }
    actions={ null }
    title={ <ResponsesTitle /> }
  >
    <SimpleForm toolbar={ <ResponseEditToolbar /> }>
      <FormDataConsumer>
        { ({ formData, ...rest }) =>
          <SurveyInput
            source="data"
            formid={ formData.form }
            memberid={ formData.member }
            status={ formData.status }
            { ...rest }
          />
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)
