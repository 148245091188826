import React, {Fragment} from 'react'
import {
  Datagrid,
  TextField, ReferenceArrayField, ChipField,
  EditButton, CloneButton, 
  translate,
  SingleFieldList
} from 'react-admin'
import {Divider, Tabs} from '@material-ui/core'

import ExportResponsesButton from './ExportResponsesButton'

import { TranslatedListTab, ApplyFilter } from '../../pst/pst'

const CloneWithoutTimestamps = props => {
    
  const { createdAt, updatedAt, ...newRecord } = props.record
  
  newRecord.status = 'draft'
  
  return (
    <CloneButton {...props} record={newRecord} />
  )
}

const DatagridContent = props => {

  return (
    <Datagrid {...props} >
        <TextField source="id" />
        <TextField source={`name.${props.locale}`} label="pst.forms.listName" />
        <ReferenceArrayField reference="groups" source="canReply" filter={{archived: false}}>
            <SingleFieldList>
                <ApplyFilter filter={{ archived: false }}>
                    <ChipField source="label"/>
                </ApplyFilter>
            </SingleFieldList>
        </ReferenceArrayField>
        <CloneWithoutTimestamps/>
        <ExportResponsesButton/>
        <EditButton/>
    </Datagrid>
  )
  
}

class TabbedDatagrid extends React.Component {
    tabs = [
        { id: 'draft', name: 'pst.forms.listTabs.draft' },
        { id: 'published', name: 'pst.forms.listTabs.published' },
        { id: 'completed', name: 'pst.forms.listTabs.completed' },
    ]

    state = { draft: [], published: [], completed: [] }

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids }
        }
        return null
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props
        setFilters({ ...filterValues, status: value })
    }

    render() {
        const { classes, filterValues, translate, ...props } = this.props
        return (
            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <TranslatedListTab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider />
                <div>
                  <DatagridContent {...props} ids={this.state[filterValues.status]} />
                </div>
            </Fragment>
        )
    }
}

export default translate(TabbedDatagrid)
