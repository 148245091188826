import { put, takeEvery } from 'redux-saga/effects'
import { changeLocale } from 'react-admin'
import { getLocale } from '../pst'

export const localeSaga = function* () {
  yield takeEvery('RA/USER_LOGIN_SUCCESS', function* () {
    yield put( changeLocale(getLocale()) )
  })
  yield takeEvery('RA/USER_LOGOUT', function* () {
    yield put( changeLocale(getLocale()) )
  })
}