import React, { Fragment, useState } from 'react'

import { MenuItemLink, Button, translate } from 'react-admin'


import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core'

import { LocaleSwitcher } from '../pst'

import TranslateIcon from '@material-ui/icons/Translate'
import SaveIcon from '@material-ui/icons/Save'

const _LocaleMenu = props => {

  const { translate } = props

  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }


  return (
    <Fragment>
        <MenuItemLink
          to="#"
          primaryText={translate('pst.localeMenu.language')}
          leftIcon={<TranslateIcon />}
          onClick={handleClick}
        />
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent style={{minWidth: 300}}>
            <LocaleSwitcher />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              size="medium"
              label="pst.localeMenu.confirm"
            >
              <SaveIcon />
            </Button>
          </DialogActions>
        </Dialog>
    </Fragment>
    
  )

}

export const LocaleMenu =  translate(_LocaleMenu)